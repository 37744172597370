import { Route, RouteConfig } from "vue-router";
import Contratos from "@/views/Contrato/Contratos.vue";
import Contrato from "@/views/Contrato/Contrato.vue";
import AceiteDeContrato from "@/views/Contrato/AceiteDeContrato.vue";
import CriaContrato from "@/views/Contrato/CriaContrato.vue";
import GenericComponent from "@/components/GenericComponent.vue";

const contratoRoutes: RouteConfig = {
  path: "/contratos",
  component: GenericComponent,
  children: [
    {
      path: "/",
      name: "Contratos",
      component: Contratos,
      meta: {
        breadCrumb: [
          {
            text: "Contratos"
          }
        ]
      }
    },
    {
      path: "/contrato/add",
      name: "addContrato",
      component: CriaContrato,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Contratos",
              to: {
                name: "Contratos"
              }
            },
            {
              text: "Novo contrato"
            }
          ];
        }
      }
    },
    {
      path: "/contrato/:idContrato",
      component: Contrato,
      name: "viewContrato",
      props: route => ({
        mode: "view",
        idContrato: route.params.idContrato
      }),
      meta: {
        breadCrumb(route: Route) {
          const idContrato = route.params.idContrato;

          return [
            {
              text: "Contratos",
              to: { name: "Contratos" }
            },
            {
              text: idContrato
            }
          ];
        }
      }
    },
    {
      path: ":idContrato",
      component: AceiteDeContrato,
      name: "AceiteDeContrato",
      props: route => ({
        idContrato: route.params.idContrato
      }),
      meta: {
        breadCrumb(route: Route) {
          const id = route.params.idContrato;
          return [
            {
              text: "Contratos",
              to: {
                name: "Contratos"
              }
            },
            {
              text: id
            }
          ];
        }
      }
    }
  ]
};

export default contratoRoutes;
