<template>
  <div>
    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@3"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="80"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@20"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

    </v-container>

    <v-container v-else>
      <form action="" name="formEmpresa" @submit.prevent="handleSave">

        <v-row dense>
          <v-col cols="12" xl="1" lg="1" md="2" sm="2">
            <v-switch
                :disabled="mode === 'view'"
                v-model="produto.status"
                :label="produto.status ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4">
            <v-switch
                :disabled="mode === 'view'"
                v-model="produto.disponivel_totem"
                label="Disponível totem"
            ></v-switch>
          </v-col>
          <v-col cols="12" xl="2" lg="3" md="3" sm="5">
            <v-switch
                :disabled="mode === 'view'"
                v-model="produto.disponivel_ecommerce"
                label="Disponível ecommerce"
            ></v-switch>
          </v-col>
          <v-col class="text-end">
            <v-btn v-if="mode === 'edit'" @click="clonarProduto" dark text color="#757575">
              <v-icon
                  left
                  dark
              >
                mdi-file-multiple
              </v-icon>
              clonar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xl="3" lg="3" md="12">
            <v-hover v-slot="{ hover }">
              <v-card elevation="0" outlined height="210">
                <v-img
                    v-if="imagePreview && produto.foto_url"
                    class="grey lighten-2"
                    :src="imagePreview.src"
                    height="100%"
                    contain
                >
                  <v-expand-transition>
                    <div
                        v-if="hover && mode !== 'view'"
                        class="d-flex justify-center align-center transition-fast-in-fast-out blue darken-2 v-card--reveal text-h2 white--text"
                        style="height: 100%; cursor: pointer"
                        @click="btnImagem()"
                    >
                      <v-file-input
                          class="d-none"
                          id="inputImagem"
                          accept="image/jpeg, image/png"
                          truncate-length="20"
                          @change="getImg"
                      ></v-file-input>
                      <v-card-subtitle class="text-center">Clique aqui para escolher a imagem</v-card-subtitle>
                    </div>
                  </v-expand-transition>
                </v-img>

                <v-img
                    v-if="produto.foto_url && !imagePreview"
                    class="grey lighten-2"
                    :src="produto.foto_url"
                    height="100%"
                    contain
                >
                  <v-expand-transition>
                    <div
                        v-if="hover && mode !== 'view'"
                        class="d-flex justify-center align-center transition-fast-in-fast-out blue darken-2 v-card--reveal text-h2 white--text"
                        style="height: 100%; cursor: pointer"
                        @click="btnImagem()"
                    >
                      <v-file-input
                          class="d-none"
                          id="inputImagem"
                          accept="image/jpeg, image/png"
                          truncate-length="20"
                          @change="getImg"
                      ></v-file-input>
                      <v-card-subtitle class="text-center">Clique aqui para escolher a imagem</v-card-subtitle>
                    </div>
                  </v-expand-transition>
                </v-img>

                <v-img
                    v-if="!produto.foto_url && imagePreview"
                    class="grey lighten-2"
                    :src="imagePreview.src"
                    height="100%"
                    contain
                >
                  <v-expand-transition>
                    <div
                        v-if="hover && mode !== 'view'"
                        class="d-flex justify-center align-center transition-fast-in-fast-out blue darken-2 v-card--reveal text-h2 white--text"
                        style="height: 100%; cursor: pointer"
                        @click="btnImagem()"
                    >
                      <v-file-input
                          class="d-none"
                          id="inputImagem"
                          accept="image/jpeg, image/png"
                          truncate-length="20"
                          @change="getImg"
                      ></v-file-input>
                      <v-card-subtitle class="text-center">Clique aqui para escolher a imagem</v-card-subtitle>
                    </div>
                  </v-expand-transition>
                </v-img>

                <v-img
                    v-if="!imagePreview && !produto.foto_url"
                    class="grey lighten-2"
                    :src="require(`@/assets/img/produto-sem-foto.png`)"
                    height="100%"
                    contain
                >
                  <v-expand-transition>
                    <div
                        v-if="hover && mode !== 'view'"
                        class="d-flex justify-center align-center transition-fast-in-fast-out blue darken-2 v-card--reveal text-h2 white--text"
                        style="height: 100%; cursor: pointer"
                        @click="btnImagem()"
                    >
                      <v-file-input
                          class="d-none"
                          id="inputImagem"
                          accept="image/jpeg, image/png"
                          truncate-length="20"
                          @change="getImg"
                      ></v-file-input>
                      <v-card-subtitle class="text-center">Clique aqui para escolher a imagem</v-card-subtitle>
                    </div>
                  </v-expand-transition>
                </v-img>

              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" xl="9" lg="9" md="12">
            <v-row>
              <v-col cols="12" sm="2" class="box_mobile_info">
                <v-text-field
                    :readonly="mode == 'view'"
                    required
                    dense
                    v-model="produto.codigo_barras"
                    label="EAN/GTIN"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                    :readonly="mode == 'view'"
                    required
                    dense
                    v-model="produto.nome"
                    label="Nome"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" class="box_mobile_info">
                <v-text-field
                  :readonly="mode == 'view'"
                  @keyup="produto.preco_custo = $masks.dinheiro(produto.preco_custo)"
                  dense
                  v-model="produto.preco_custo"
                  label="Preço Custo"
                  @blur="handleSugestaoPreco('custo')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" class="box_mobile_info">
                <v-text-field
                  :readonly="mode == 'view'"
                  @keyup="produto.margem_sobre_custo = $masks.dinheiro(produto.margem_sobre_custo)"
                  dense
                  v-model="produto.margem_sobre_custo"
                  label="Margem sobre custo %"
                  @blur="handleSugestaoPreco('margem')"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" class="box_mobile_info">
                <v-text-field
                  :readonly="mode == 'view'"
                  required
                  @keyup="produto.valor = $masks.dinheiro(produto.valor)"
                  dense
                  v-model="produto.valor"
                  label="Valor"
                  :hint="msgPrecoSugestao"
                  persistent-hint
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" class="box_mobile_info">
                <v-text-field
                  :readonly="mode == 'view'"
                  dense
                  v-model="produto.marca"
                  label="Marca"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" class="box_mobile_info">
                <v-autocomplete
                  dense
                  no-data-text="Cadastre os setores"
                  :items="setores"
                  :readonly="mode == 'view'"
                  :item-text="item => `${item.nome}`"
                  item-value="id"
                  v-model="produto.setor.id"
                  label="Setor"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="3" class="box_mobile_info">
                <v-autocomplete
                  dense
                  no-data-text="Cadastre as categorias"
                  :items="categorias"
                  :readonly="mode == 'view'"
                  :item-text="item => `${item.nome}`"
                  required
                  item-value="id"
                  v-model="produto.categoria.id"
                  label="Categoria"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="3" class="box_mobile_info">
                <v-autocomplete
                    dense
                    no-data-text="Adicione as categorias no ERP"
                    :items="categoriasContabil"
                    :readonly="mode == 'view'"
                    :item-text="item => `${item.codigo} - ${item.nome}`"
                    item-value="id"
                    v-model="produto.categoria_contabil.id"
                    label="Tipo de entrada no contas a receber"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12">
                <v-textarea
                    outlined
                    rows="2"
                    counter="150"
                    :readonly="mode == 'view'"
                    label="Descrição"
                    hint="Detalhamento do produto"
                    v-model="produto.descricao"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="box_mobile">
          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                type="number"
                :readonly="mode == 'view'"
                dense
                v-model="produto.conteudo"
                label="Conteúdo"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.altura"
                label="Altura"
                @keyup="produto.altura = $masks.dinheiro(produto.altura)"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.peso_bruto"
                label="Peso bruto"
                @keyup="produto.peso_bruto = $masks.dinheiro(produto.peso_bruto)"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.peso_liquido"
                label="Peso liquido"
                @keyup="produto.peso_liquido = $masks.dinheiro(produto.peso_liquido)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.profundidade"
                label="Profundidade"
                @keyup="produto.profundidade = $masks.dinheiro(produto.profundidade)"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.largura"
                label="Largura"
                @keyup="produto.largura = $masks.dinheiro(produto.largura)"
            >
            </v-text-field>
          </v-col>

        </v-row>

        <v-row class="box_mobile">
          <v-col>
            <v-autocomplete
                dense
                :items="icms_origem"
                :readonly="mode == 'view'"
                :item-text="item => item.texto"
                item-value="valor"
                v-model="produto.icms_origem"
                label="Origem ICMS"
            >
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete
                dense
                :items="situacao_tributaria"
                :readonly="mode == 'view'"
                :item-text="item => item.texto"
                item-value="valor"
                v-model="produto.icms_situacao_tributaria"
                label="Situacao Tributaria ICMS"
            >
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete
                dense
                :items="modalidade_basecalculo_icms"
                :readonly="mode == 'view'"
                :item-text="item => item.texto"
                item-value="valor"
                v-model="produto.icms_modalidade_base_calculo"
                label="Modalidade Base Cálculo ICMS"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="box_mobile">
          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.ncm"
                label="NCM"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.cfop"
                label="CFOP"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.cest"
                label="CEST"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.aliquota_icms"
                label="Alíquota ICMS"
                @keyup="
              produto.aliquota_icms = $masks.dinheiro(produto.aliquota_icms)
            "
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.aliquota_pis"
                label="Alíquota PIS"
                @keyup="
              produto.aliquota_pis = $masks.dinheiro(produto.aliquota_pis)
            "
            >
            </v-text-field>
          </v-col>

          <v-col cols="2" class="box_mobile_info">
            <v-text-field
                :readonly="mode == 'view'"
                dense
                v-model="produto.aliquota_cofins"
                label="Alíquota COFINS"
                @keyup="
              produto.aliquota_cofins = $masks.dinheiro(produto.aliquota_cofins)
            "
            >
            </v-text-field>
          </v-col>

          <v-col cols="3" class="box_mobile_info">
            <v-text-field
              maxlength="40"
              :readonly="mode == 'view'"
              dense
              v-model="produto.id_externo"
              label="ID Externo"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row>
          <v-col class="text-end">
            <v-btn dark color="primary" type="submit" v-if="mode != 'view'">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CryptoJS from "crypto-js";

export default {
  props: {
    mode: String,
    id: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      loadingSkeleton: false,
      imagem: null,
      imagePreview: null,
      produto: {
        descricao: "",
        status: true,
        valor: 0,
        preco_custo: 0,
        margem_sobre_custo: 0,
        categoria: {},
        setor: {},
        categoria_contabil: {},
        disponivel_ecommerce: false,
        disponivel_totem: false,
        foto_url: null,
        id_externo: null
      },
      categoriasContabil: [],
      categorias: [],
      setores: [],
      msgPrecoSugestao: null,
      situacao_tributaria: [
        {
          valor: "SIMPLES_TIBUTADO_SEM_CREDITO",
          texto: "102-Tributada pelo Simples Nacional sem permissao de credito"
        },
        {
          valor: "SIMPLES_ISENTO_FAIXA_RECEITA",
          texto: "103-Isenção de ICMS no simples para faixa de receita bruta"
        },
        {
          valor: "SIMPLES_IMUNE",
          texto: "300-Imune"
        },
        {
          valor: "SIMPLES_ISENTO",
          texto: "400-Não tributada pelo Simples Nacional"
        },
        {
          valor: "SIMPLES_ICMS_COBRADO_SUBSTITUICAO_TRIBUTARIA",
          texto:
            "500-ICMS cobrado anteriormente por substituicao tributária ou antecipacao"
        },
        {
          valor: "TRIBUTADO_INTEGRALMENTE",
          texto: "00-Tributada integralmente"
        },
        {
          valor: "ISENTA",
          texto: "40-Isenta"
        },
        {
          valor: "COBRADO_SUBSTITUICAO_TRIBUTARIA",
          texto: "60-ICMS cobrado anteriormente por substituicao tributária"
        },
        {
          valor: "NAO_TRIBUTADA",
          texto: "41-Não tributada"
        }
      ],
      modalidade_basecalculo_icms: [
        {
          valor: "VALOR_OPERACAO",
          texto: "Valor da operação"
        },
        {
          valor: "MVA",
          texto: "Margem de valor agregado (%)"
        },
        {
          valor: "PAUTA",
          texto: "Pauta (valor)"
        },
        {
          valor: "PRECO_TABELADO",
          texto: "Preço tabelado máximo (valor)"
        }
      ],
      icms_origem: [
        {
          valor: "NACIONAL",
          texto: "Nacional"
        },
        {
          valor: "ESTRANGEIRA_DIRETA",
          texto: "Estrangeira (importação direta)"
        },
        {
          valor: "ESTRANGEIRA_INTERNO",
          texto: "Estrangeira (adquirida no mercado interno)"
        },
        {
          valor: "NACIONAL_MAIS40_ESTRANGEIRO",
          texto: "Nacional com mais de 40% de conteúdo estrangeiro"
        },
        {
          valor: "NACIONAL_BASICO",
          texto: "Nacional produzida através de processos produtivos básicos"
        },
        {
          valor: "NACIONAL_MENOS40_ESTRANGEIRO",
          texto: "Nacional com menos de 40% de conteúdo estrangeiro"
        },
        {
          valor: "ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_NACIONAL_SIMILAR",
          texto: "Estrangeira (importação direta) sem nacional similar"
        },
        {
          valor: "ESTRANGEIRA_ADQ_INTERNO_SEM_NACIONAL_SIMILAR",
          texto:
            "Estrangeira (adquirida no mercado interno) sem nacional similar"
        }
      ]
    };
  },
  mounted() {
    this.getCategoriasContabil();
    this.getCategorias();
    this.getSetores();

    if (this.mode != "create") this.getProduto();
  },
  computed: {
    ...mapState(["empresaAtual", "user"])
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getProduto() {
      if (this.mode != "create") {
        this.loadingSkeleton = true;
        this.$axios
          .get(`/produto/${this.id}`)
          .then(response => {
            this.produto = response.data;
            if (!this.produto.categoria_contabil) {
              this.produto.categoria_contabil = {};
            }

            if (!this.produto.categoria) {
              this.produto.categoria = {};
            }

            if (!this.produto.setor) {
              this.produto.setor = {};
            }

            this.produto.status =
              this.produto.status === "ATIVO" ? true : false;
            if (this.produto.valor) {
              this.produto.valor = this.$masks.dinheiro(
                this.produto.valor.toFixed(2)
              );
            }

            if (this.produto.preco_custo) {
              this.produto.preco_custo = this.$masks.dinheiro(
                  this.produto.preco_custo.toFixed(2)
              );

              this.handleSugestaoPreco("custo");
            }

            if (this.produto.margem_sobre_custo) {
              this.produto.margem_sobre_custo = this.$masks.dinheiro(
                  this.produto.margem_sobre_custo.toFixed(2)
              );

              this.handleSugestaoPreco("margem");
            }

            //INSS
            if (this.produto.aliquota_icms) {
              this.produto.aliquota_icms = this.$masks.dinheiro(
                this.produto.aliquota_icms.toFixed(2)
              );
            }

            //PIS
            if (this.produto.aliquota_pis) {
              this.produto.aliquota_pis = this.$masks.dinheiro(
                this.produto.aliquota_pis.toFixed(2)
              );
            }
            //COFINS
            if (this.produto.aliquota_cofins) {
              this.produto.aliquota_cofins = this.$masks.dinheiro(
                this.produto.aliquota_cofins.toFixed(2)
              );
            }

            this.loadingSkeleton = false;
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Buscar Produto",
              text: error.response.data
            });
          });
      }
    },
    getCategoriasContabil() {
      this.$axios
        .get(`/categoria-contabil/empresa/${this.empresaAtual.id}?tipo=ENTRADA`)
        .then(response => {
          this.categoriasContabil = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Buscar categoria",
            text: error.response.data
          });
        });
    },
    getCategorias() {
      this.$axios
        .get(`/categoria/empresa/${this.empresaAtual.id}`)
        .then(response => {
          this.categorias = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Buscar categoria",
            text: error.response.data
          });
        });
    },
    getSetores() {
      this.$axios
        .get(`/setor/empresa/${this.empresaAtual.id}`)
        .then(response => {
          this.setores = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Buscar setor",
            text: error.response.data
          });
        });
    },
    async handleSave() {
      this.produto.status = this.produto.status ? "ATIVO" : "INATIVO";
      this.produto.empresa_id = this.empresaAtual.id;
      this.produto.valor = this.parseToFloat(this.produto.valor ? this.produto.valor : 0);
      this.produto.preco_custo = this.parseToFloat(this.produto.preco_custo ? this.produto.preco_custo : 0);
      this.produto.margem_sobre_custo = this.parseToFloat(this.produto.margem_sobre_custo ? this.produto.margem_sobre_custo : 0);

      this.produto.aliquota_icms = this.parseToFloat(this.produto.aliquota_icms ? this.produto.aliquota_icms : 0);
      this.produto.aliquota_pis = this.parseToFloat(this.produto.aliquota_pis ? this.produto.aliquota_pis : 0);
      this.produto.aliquota_cofins = this.parseToFloat(this.produto.aliquota_cofins ? this.produto.aliquota_cofins : 0);

      this.produto.conteudo = this.parseToFloat(this.produto.conteudo ? this.produto.conteudo : 0);
      this.produto.altura = this.parseToFloat(this.produto.altura ? this.produto.altura : 0);
      this.produto.profundidade = this.parseToFloat(this.produto.profundidade ? this.produto.profundidade : 0);
      this.produto.largura = this.parseToFloat(this.produto.largura ? this.produto.largura : 0);
      this.produto.peso_bruto = this.parseToFloat(this.produto.peso_bruto ? this.produto.peso_bruto : 0);
      this.produto.peso_liquido = this.parseToFloat(this.produto.peso_liquido ? this.produto.peso_liquido : 0);

      this.produto.categoria_contabil = {
        id : this.produto.categoria_contabil ? this.produto.categoria_contabil.id: null
      };

      this.mode === "create" || this.mode === "clone" ? await this.save() : await this.edit();
    },

    async save() {
      this.$axios
        .post("produto", this.produto)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: `Produto cadastrado com sucesso.`,
            life: 2000
          });
          this.handleUploadImage(response.data);
          this.$router.push({
            name: "Produtos"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar Produto",
            text: error.response.data
          });
        });
    },

    async edit() {
      this.$axios
        .put("produto", this.produto)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: `Produto atualizado com sucesso.`,
            life: 2000
          });
          this.handleUploadImage(response.data);
          this.$router.push({
            name: "Produtos"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Produto",
            text: error.response.data
          });
        });
    },

    async handleUploadImage(product) {
      if (!this.imagem) {
        return;
      }

      const boundaryTest = CryptoJS.SHA1(this.imagem.name).toString();
      const formData = new FormData();
      formData.append("file", this.imagem);

      await this.$axios
        .post(
          `/produto/${product.id}/upload-imagem`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "file-token": boundaryTest
            }
          }
        )
        .then()
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Envio da imagen",
            text: error.response.data
          });
        });
    },

    btnImagem() {
      const btnFile =  document.getElementById('inputImagem');
      btnFile.click();
    },

    getImg(e) {
      if(e) {
        this.imagem = e;
        this.imagePreview = {src: URL.createObjectURL(e)}
      }
    },

    clonarProduto() {
      this.produto.codigo_barras = null;
      this.produto.foto_url = null;
      this.produto.id = null;
      this.$router.push({
        name: "cloneProduto",
        params: {
          id: this.id
        }
      });
    },

    handleSugestaoPreco(item) {
      const margem = this.parseToFloat(this.produto.margem_sobre_custo);
      const custo = this.parseToFloat(this.produto.preco_custo);

      if ( (item === 'custo' && margem > 0) || (item === 'margem' && custo > 0) ) {
        const porcentagem = margem / 100;
        const conta = custo * porcentagem;
        const precoSugestao = custo + conta;

        this.atualizarPreco(precoSugestao);
      }
    },

    atualizarPreco(precoSugestao) {
      if (this.produto.valor && this.parseToFloat(this.produto.valor) > 0) {
        this.msgPrecoSugestao = `Sugestão de preço: R$ ${this.$masks.dinheiro(precoSugestao.toFixed(2))}`;
      } else {
        this.produto.valor = this.$masks.dinheiro(precoSugestao.toFixed(2));
      }
    },

  }
};
</script>

<style>
@media (max-width: 900px) {
  .box_mobile {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
  .box_mobile_info {
    max-width: 100% !important;
  }
}
</style>
