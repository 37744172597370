<template>
  <v-container fluid v-if="assinatura.id">
    <div class="logo">
      <img :src="getLogoImage" alt="" />
    </div>

    <v-row dense>
      <v-col cols="4" class="d-flex flex-column py-3">
        <span class="headline font-weight-bold ">
          Protocolo #{{ assinatura.protocolo }}
        </span>
        <span class="caption">
          Recorrência
          {{
            assinatura.quantidade_cobranca == 0
              ? "ilimitada"
              : `${assinatura.quantidade_cobranca} cobranças`
          }}
        </span>
        <span class="caption">
          Criado
          {{
            assinatura.data_cadastro
              | formatDate({ dateStyle: "short", timeStyle: "short" })
          }}
        </span>
        <span class="caption">
          Atualizado
          {{
            assinatura.data_atualizada
              | formatDate({ dateStyle: "short", timeStyle: "short" })
          }}
        </span>
        <span class="caption">
          Válidade
          {{ assinatura.data_validade | formatDate({ dateStyle: "short" }) }}
        </span>
        <span class="caption" v-if="assinatura.data_fim_degustacao">
          Termino degustação
          {{
            assinatura.data_fim_degustacao | formatDate({ dateStyle: "short" })
          }}
        </span>
      </v-col>

      <v-divider vertical></v-divider>
      <v-col cols="4" class="text-center pa-0">
        <p class="headline font-weight-bold">
          {{ assinatura.plano.descricao }}
        </p>
        <span class="caption font-weight-bold green--text">R$</span>
        <span class="text-h3 font-weight-bold">
          {{ valorPlano | formatMoney({ minimumFractionDigits: 2 }) }}</span
        >
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="text-center pa-0">
        <v-chip
          class="ma-2 text-center"
          :color="STATUS_ASSINATURA[assinatura.status].cor"
          style="justify-content: center; min-width: 100px"
          label
          text-color="white"
        >
          {{ STATUS_ASSINATURA[assinatura.status].texto }}
        </v-chip>
        <p class="text-h6 font-weight-bold">
          <i
            v-if="assinatura.forma_pagamento === 'CARTAO'"
            class="far fa-credit-card"
          ></i>
          <i
            v-if="assinatura.forma_pagamento === 'BOLETO'"
            class="far fa-file-alt"
          ></i>
          {{ assinatura.forma_pagamento === "CARTAO" ? "Cartão" : "Boleto" }}
        </p>
        <p v-if="assinatura.forma_pagamento === 'CARTAO' && assinatura.cartao">
          {{ assinatura.cartao.first_digits }} *** **** ****
          {{ assinatura.cartao.last_digits }}
        </p>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="4" class="d-flex flex-column py-3">
        <p class="text-h6 font-weight-bold">Titular</p>
        <div class="d-flex flex-column custom-card" v-if="assinatura.titular">
          <span>
            {{ assinatura.titular.nome }}
          </span>
          <span class="text-caption">
            {{ $masks.telefone(assinatura.titular.telefone_celular) }}
          </span>
          <span class="text-caption">
            {{ assinatura.titular.email }}
          </span>
          <span class="text-caption">
            {{ assinatura.titular.endereco }}, {{ assinatura.titular.numero }} -
            {{ assinatura.titular.cidade }} ,
            {{ assinatura.titular.uf }}
          </span>
        </div>

        <p class="text-h6 font-weight-bold" style="margin-top: 0.5rem">
          Emissor
        </p>
        <div
          v-if="assinatura.empresa"
          class="d-flex flex-column custom-card mb-4"
        >
          <span>
            {{ assinatura.empresa.nome_fantasia }}
          </span>
          <span>
            {{ assinatura.empresa.razao_social }}
          </span>
          <span class="text-caption">
            {{ assinatura.empresa.cnpj }}
          </span>
          <span class="text-caption">
            {{ assinatura.empresa.endereco }}, {{ assinatura.empresa.numero }} -
            {{ assinatura.empresa.cidade }} ,
            {{ assinatura.empresa.uf }}
          </span>
        </div>

        <v-row v-if="!assinatura.cartao && assinatura.status != 'CANCELADA'">
          <v-col class="input_mobile">
            <Button
              icon="pi pi-dollar"
              label="PAGAR AGORA"
              @click="pagamentoCartao"
              class="p-button-outlined button_mobile"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-center">
        <p class="text-h6 font-weight-bold">
          {{ assinatura.ultima_cobranca ? "Ultima Transação" : "" }}
        </p>

        <v-card
          outlined
          color="green"
          v-if="
            assinatura.ultima_cobranca &&
              assinatura.ultima_cobranca.tipo === 'BOLETO'
          "
        >
          <v-card-text class="grey lighten-4 pa-0">
            <v-row dense class="text-start">
              <v-col class="text-start px-4" cols="3">
                <span class="text-subtitle-1 font-italic">Método</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">{{
                  assinatura.ultima_cobranca.tipo
                }}</span>
              </v-col>
              <v-col class="text-start px-4" cols="3">
                <span class="text-subtitle-1 font-italic">Status</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">{{
                  assinatura.ultima_cobranca.status
                }}</span>
              </v-col>
              <v-col class="text-start px-4" cols="3">
                <span class="text-subtitle-1 font-italic">Valor</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold"
                  >R$
                  {{
                    assinatura.ultima_cobranca.valor
                      | formatMoney({ minimumFractionDigits: 2 })
                  }}</span
                >
              </v-col>
            </v-row>

            <v-row dense class="text-start">
              <v-col class="text-start px-4" cols="3">
                <span class="text-subtitle-1 font-italic">Vencimento</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{
                    assinatura.ultima_cobranca.vencimento_boleto
                      | formatDate({ dateStyle: "short" })
                  }}
                </span>
              </v-col>
              <v-col class="text-start px-4" cols="2">
                <span class="text-subtitle-1 font-italic">Juros</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{ assinatura.ultima_cobranca.juros_atraso_boleto }}%
                </span>
              </v-col>
              <v-col class="text-start px-4" cols="2">
                <span class="text-subtitle-1 font-italic">Multa</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{ assinatura.ultima_cobranca.multa_atraso_boleto }}%
                </span>
              </v-col>
            </v-row>
            <v-row dense class="text-start">
              <v-col class="text-start px-4" cols="3">
                <span class="text-subtitle-1 font-italic">Documento</span>
                <v-divider width="200px" class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{ assinatura.ultima_cobranca.cliente.cpf
                  }}{{ assinatura.ultima_cobranca.cliente.cnpj }}
                </span>
              </v-col>
              <v-col class="text-start px-4">
                <span class="text-subtitle-1 font-italic">Cliente</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{ assinatura.ultima_cobranca.cliente.nome }}
                </span>
              </v-col>
            </v-row>
            <v-row dense class="text-start">
              <v-col class="text-start px-4" cols="3">
                <span class="text-subtitle-1 font-italic">Telefone</span>
                <v-divider class="mb-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{ assinatura.ultima_cobranca.cliente.telefone_celular }}
                </span>
              </v-col>

              <v-col class="text-start px-4">
                <span class="text-subtitle-1 font-italic">E-mail</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-2 font-weight-bold">
                  {{ assinatura.ultima_cobranca.cliente.email }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  rounded
                  color="primary"
                  dark
                  :href="assinatura.ultima_cobranca.url_boleto"
                  target="_blank"
                >
<!--                  <i class="fas fa-barcode fa-style mr-3"></i>-->
                  <v-icon class="mr-3"> mdi-barcode </v-icon>
                  Visualizar
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-center px-4">
                <span class="text-subtitle-1 font-weight-bold">{{
                  assinatura.ultima_cobranca.bar_code_boleto
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          outlined
          color="green"
          class="cartao"
          v-if="
            assinatura.ultima_cobranca &&
              assinatura.ultima_cobranca.tipo === 'CREDITO'
          "
        >
          <v-card-text class="grey lighten-4 pa-0">
            <v-row dense>
              <v-col class="text-start pl-6 py-2">
                <i
                  class="far fa-credit-card green--text"
                  style="font-size: 2.5em"
                ></i>
              </v-col>
            </v-row>

            <v-row dense class="text-start">
              <v-col cols="3" class="text-start px-4">
                <span class="overline font-italic">Transação</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-1 font-weight-bold">{{
                  assinatura.ultima_cobranca.id
                }}</span>
              </v-col>

              <v-col cols="3" class="text-start px-4">
                <span class="overline font-italic">Status</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-2">{{
                  assinatura.ultima_cobranca.status
                }}</span>
              </v-col>

              <v-col cols="3" class="text-start px-4">
                <span class="overline font-italic">Valor</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-2"
                  >R$
                  {{
                    assinatura.ultima_cobranca.valor
                      | formatMoney({ minimumFractionDigits: 2 })
                  }}</span
                >
              </v-col>

              <v-col class="text-start px-4">
                <span class="overline font-italic">Data</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-2">{{
                  assinatura.ultima_cobranca.data_criacao
                    | formatDate({ dateStyle: "short", timeStyle: "short" })
                }}</span>
              </v-col>
            </v-row>

            <v-row dense class="text-start">
              <v-col cols="6" class="text-start px-4">
                <span class="overline font-italic">Portador</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-1 font-weight-bold">{{
                  assinatura.ultima_cobranca.card_holder_name
                }}</span>
              </v-col>

              <v-col class="text-start px-4">
                <span class="overline font-italic">Número</span>
                <v-divider class="mb-2 mr-2"></v-divider>
                <span class="text-subtitle-2"
                  >{{ assinatura.ultima_cobranca.card_first_digits }} *** ****
                  ****{{ assinatura.ultima_cobranca.card_last_digits }}</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="text-center">
      <v-col v-if="assinatura.transacoes && assinatura.transacoes.length > 0">
        <v-row>
          <v-col class="justify-center" cols="6">
            <div>
              <p class="text-h6 font-weight-bold">
<!--                <i class="fas fa-history mr-2"></i>Histórico de Transações-->
                <v-icon class="mr-2"> mdi-history </v-icon>Histórico de Transações
              </p>
              <v-divider></v-divider>
            </div>
            <DataTable
              :value="assinatura.transacoes"
              sortField="data_criacao"
              :sortOrder="-1"
              responsiveLayout="stack"
              breakpoint="960px"
              scrollHeight="flex-row"
            >
              <Column field="id" header="NSU"></Column>
              <Column field="status" header="Status">
                <template #body="slot">
                  <v-chip
                    :color="statusStyle(slot.data.status)"
                    text-color="white"
                  >
                    {{ slot.data.status.toLowerCase() }}
                  </v-chip>
                </template>
              </Column>
              <Column field="tipo" header="Tipo">
                <template #body="slot">
                  {{ TIPO_TRANSACAO[slot.data.tipo] }}
                </template>
              </Column>
              <Column field="data_criacao" header="Efetuada">
                <template #body="slot">
                  {{
                    slot.data.data_criacao | formatDate({ dateStyle: "short" })
                  }}
                </template>
              </Column>
              <Column field="vencimento_boleto" header="Vencimento">
                <template #body="slot">
                  {{
                    slot.data.vencimento_boleto
                      | formatDate({ dateStyle: "short" })
                  }}
                </template>
              </Column>
              <Column field="nota_fiscal" header="Nfs-e">
                <template #body="slot">
                  <Button
                    icon="pi pi-file"
                    v-if="slot.data.nota_fiscal"
                    @click="verNota(slot.data.nota_fiscal)"
                    class="p-button-rounded p-button-outlined p-button-secondary"
                  />
                </template>
              </Column>

              <Column header="Ver">
                <template #body="slot">
                  <Button
                    icon="pi pi-search"
                    v-if="slot.data.tipo === 'BOLETO'"
                    @click="openUrl(slot.data.url_boleto)"
                    class="p-button-rounded p-button-outlined"
                  />
                </template>
              </Column>
            </DataTable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!----------------------- Dialogs ---------------------->

    <v-overlay :value="showFormCartao" v-if="showFormCartao">
      <transition name="bounce">
        <CardForm
          :form-data="cartao"
          :cliente="assinatura.titular"
          @cartao="cartaoInformado"
          :maxParcelas="1"
          v-show="showFormCartao"
          @cancela-pagamento-cartao="closeFormCartao"
        />
      </transition>
    </v-overlay>

    <DialogNotaFiscal
      :nota="nota"
      v-if="showNota"
      :showDialog="showNota"
      :cancela="() => (showNota = false)"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CardForm from "@/components/Cartao/CardForm.vue";
import DialogNotaFiscal from "@/components/Dialogs/DialogNotaFiscal.vue";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "PagamentoAssinatura",
  components: {
    CardForm,
    Button,
    DialogNotaFiscal,
    DataTable,
    Column
  },
  props: {
    id: String
  },
  data() {
    return {
      assinatura: {
        id: null
      },
      cartao: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        cardNumberNotMask: "",
        parcels: 1
      },
      nota: {},
      showNota: false,
      showFormCartao: false
    };
  },

  mounted() {
    this.getAssinatura();
  },
  computed: {
    ...mapState(["config"]),
    getLogoImage() {
      if (!this.config.images.logo) return;
      return this.config.images.logo;
    },
    valorPlano() {
      let total = 0.0;
      if (this.assinatura.plano && this.assinatura.plano.itens) {
        this.assinatura.plano.itens.forEach(item => {
          total =
            parseFloat(total) +
            (parseFloat(item.quantidade * item.valor) - item.desconto);
        });
      }
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    cartaoInformado() {
      const obj = {
        number: this.cartao.cardNumberNotMask.replaceAll(" ", ""),
        holder_name: this.cartao.cardName,
        expires: this.cartao.cardMonth
          .toString()
          .concat(this.cartao.cardYear.toString().substring(2, 4)),
        cvv: this.cartao.cardCvv,
        parcels: this.cartao.parcels
      };

      const data = {
        holder_name: this.cartao.cardName,
        expires: this.cartao.cardMonth
          .toString()
          .concat(this.cartao.cardYear.toString().substring(2, 4)),
        number: this.cartao.cardNumberNotMask.replaceAll(" ", ""),
        cvv: this.cartao.cardCvv
      };

      this.$axios
        .post(`assinatura/${this.assinatura.id}/pagar`, data)
        .then(obj => {
          this.assinatura = obj.data;
          this.closeFormCartao();
          this.openDialogMessage({
            type: "success",
            title: "Sucesso",
            text: "A assinatura foi paga com sucesso!"
          });
        })
        .catch(error => {
          if (error.response.data.falha) {
            this.openDialogMessage({
              type: "error",
              title: `Pagamento ${error.response.data.status}`,
              text: `${error.response.data.id}: ${error.response.data.falha}`
            });
          } else {
            this.openDialogMessage({
              type: "error",
              title: `Falha na transação`,
              text: `${error.response.data}`
            });
          }
        });
    },
    pagamentoCartao() {
      this.showFormCartao = true;
    },
    closeFormCartao() {
      this.cartao = {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        cardNumberNotMask: "",
        parcels: 1
      };

      this.showFormCartao = false;
    },
    verNota(nota) {
      this.nota = nota;
      this.showNota = true;
    },
    async getAssinatura() {
      await this.$axios
        .get(`/public/assinatura/${this.id}`)
        .then(response => {
          this.assinatura = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Assinatura",
            text: error.response.data
          });
          this.showDialogEditPagto = false;
        });
    },
    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir o boleto");
      }
    }
  }
};
</script>

<style scoped>
.container--fluid {
  padding: 2rem;
}
.custom-card {
  border-left: 2px solid black;
  padding: 10px;
}
.logo {
  width: 200px;
  height: 80px;
}
.logo img {
  width: 100%;
  height: auto;
}

.status-container {
  width: 100px;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.text-caption {
  display: block;
}

.titulo {
  font-size: 1.3rem;
}

i {
  font-size: 1.3rem;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 900px) {
  .col,
  .col-1,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }

  .container--fluid {
    padding: 1rem;
  }
  .input_mobile {
    max-width: 20rem;
    margin: 0 auto;
    text-align: center;
  }
  .button_mobile {
    width: 100%;
  }
}
</style>
