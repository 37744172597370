<template>
  <div>
    <div class="logo" :style="`background-color: ${getSecondaryColor}`">
      <v-row class="mx-auto">
        <v-col>
          <div>
            <img :src="getLogoImage" alt="" />
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-if="loadingSkeleton">
      <v-row class="mx-auto mt-3">
        <v-col>
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="150"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="mx-auto mt-3">
        <v-col>
          <v-skeleton-loader
              :loading="true"
              type="image@2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="mx-auto mt-3">
        <v-col>
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="cobranca.id && !loadingSkeleton">
      <div v-if="cobranca.status === 'QUITADO'">
        <div class="container">
          <div class="centralizada mt-15">
            <div>
              <img
                  class="emote-cobranca"
                  :src="require(`@/assets/img/concluido.png`)"
              />
            </div>
            <div>
              <b class="text-cobranca">
                <span>Cobrança paga com sucesso.</span><br>
              </b>
            </div>
            <div class="mt-5">
              <v-divider></v-divider>
            </div>
            <div>
              <v-card elevation="0" outlined class="py-0 px-0 billing-container">
                <span class="billing-title text-caption">Emissor</span>
                <div class="d-flex justify-start">
                  <div class="mt-3 ml-3">
                    <span class="text-caption"><b>{{ $masks.cnpj(cobranca.empresa.cnpj) }}</b></span>
                    <span class="d-flex justify-start text-caption">{{ cobranca.empresa.nome_fantasia }}</span>
                    <span class="d-flex justify-start text-caption">{{ cobranca.empresa.razao_social }}</span>
                  </div>
                </div>
              </v-card>
            </div>
            <div>
              <v-row class="mx-auto">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card-title>Itens</v-card-title>
                </v-col>
                <v-col cols="12" class="mt-n9">
                  <DataTable
                      :value="cobranca.itens"
                      responsiveLayout="stack"
                      breakpoint="780px"
                      scrollHeight="flex-row"
                  >
                    <Column field="quantidade" header="QTD" />
                    <Column header="Item">
                      <template #body="slot">
                        <div v-if="slot.data.servico">
                          <p>{{ slot.data.servico.sku }} - {{ truncateNomeItem(slot.data.servico.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.servico.descricao) }}</p>
                        </div>
                        <div v-if="slot.data.produto">
                          <p>{{ slot.data.produto.codigo_barras }} - {{ truncateNomeItem(slot.data.produto.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.produto.descricao) }}</p>
                        </div>
                      </template>
                    </Column>
                    <Column header="Valor">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{
                          (slot.data.quantidade * slot.data.valor)
                              | formatMoney({ minimumFractionDigits: 2 })
                        }}
                      </template>
                    </Column>
                  </DataTable>

                  <div
                      style="border-top: 1px solid gray"
                      class="text-end mt-4"
                  >
                                          <span class="text-caption green--text" style="display: inline-block;"
                                          >R$</span
                                          >
                    <span class="text-h3 green--text">{{
                        totalItens | formatMoney({ minimumFractionDigits: 2 })
                      }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row class="mx-auto mt-3">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card-title>Transações</v-card-title>
                </v-col>
                <v-col cols="12" class="mt-n9">
                  <DataTable
                      :value="cobranca.transacoes"
                      responsiveLayout="stack"
                      breakpoint="780px"
                      scrollHeight="flex-row"
                  >
                    <Column field="id" header="NSU"></Column>
                    <Column field="status" header="Status">
                      <template #body="slot">
                        <v-chip
                            :color="statusStyle(slot.data.status)"
                            text-color="white"
                        >
                          {{ slot.data.status.toLowerCase() }}
                        </v-chip>
                      </template>
                    </Column>
                    <Column field="tipo" header="Tipo">
                      <template #body="slot">
                        {{ TIPO_TRANSACAO[slot.data.tipo] }}
                      </template>
                    </Column>
                    <Column v-if="cobranca.vencimento_boleto" field="vencimento_boleto" header="Vencimento">
                      <template #body="slot">
                        {{
                          slot.data.vencimento_boleto
                              | formatDate({ dateStyle: "short" })
                        }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column field="nota_fiscal" header="NFS-e">
                      <template #body="slot">
                        <Button
                            icon="pi pi-file"
                            v-if="slot.data.nota_fiscal && slot.data.nota_fiscal.url"
                            @click="openUrl(slot.data.nota_fiscal.url)"
                            class="p-button-rounded p-button-outlined p-button-secondary"
                        />
                        <Button
                            icon="pi pi-file"
                            disabled
                            v-else
                            @click="openUrl(slot.data.nota_fiscal.url)"
                            class="p-button-rounded p-button-outlined p-button-secondary"
                        />
                      </template>
                    </Column>
                  </DataTable>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>

      <div v-if="cobranca.status === 'REGULAR'">
        <div class="container">
          <div class="centralizada mt-15">
            <div>
              <img
                  class="emote-cobranca"
                  :src="require(`@/assets/img/pendente.png`)"
              />
            </div>
            <div>
              <b class="text-cobranca">
                <span>Cobrança pendente</span><br>
              </b>
            </div>
            <div class="mt-5">
              <v-divider></v-divider>
            </div>
            <div>
              <v-card elevation="0" outlined class="py-0 px-0 billing-container">
                <span class="billing-title text-caption">Emissor</span>
                <div class="d-flex justify-start">
                  <div class="mt-3 ml-3">
                    <span class="text-caption"><b>{{ $masks.cnpj(cobranca.empresa.cnpj) }}</b></span>
                    <span class="d-flex justify-start text-caption">{{ cobranca.empresa.nome_fantasia }}</span>
                    <span class="d-flex justify-start text-caption">{{ cobranca.empresa.razao_social }}</span>
                  </div>
                </div>
              </v-card>
            </div>
            <div>
              <v-row class="mx-auto">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card-title>Itens</v-card-title>
                </v-col>
                <v-col cols="12" class="mt-n9">
                  <DataTable
                      :value="cobranca.itens"
                      responsiveLayout="stack"
                      breakpoint="780px"
                      scrollHeight="flex-row"
                  >
                    <Column field="quantidade" header="QTD" />
                    <Column header="Item">
                      <template #body="slot">
                        <div v-if="slot.data.servico">
                          <p>{{ slot.data.servico.sku }} - {{ truncateNomeItem(slot.data.servico.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.servico.descricao) }}</p>
                        </div>
                        <div v-if="slot.data.produto">
                          <p>{{ slot.data.produto.codigo_barras }} - {{ truncateNomeItem(slot.data.produto.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.produto.descricao) }}</p>
                        </div>
                      </template>
                    </Column>
                    <Column header="Valor">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{
                          (slot.data.quantidade * slot.data.valor)
                              | formatMoney({ minimumFractionDigits: 2 })
                        }}
                      </template>
                    </Column>
                  </DataTable>

                  <div
                      style="border-top: 1px solid gray"
                      class="text-end mt-4"
                  >
                                          <span class="text-caption green--text" style="display: inline-block;"
                                          >R$</span
                                          >
                    <span class="text-h3 green--text">{{
                        totalItens | formatMoney({ minimumFractionDigits: 2 })
                      }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row class="mx-auto mt-3">
                <v-col cols="12" class="d-flex justify-center">
                  <v-card-title>Transações</v-card-title>
                </v-col>
                <v-col cols="12" class="mt-n9">
                  <DataTable
                      :value="cobranca.transacoes"
                      responsiveLayout="stack"
                      breakpoint="780px"
                      scrollHeight="flex-row"
                  >
                    <Column field="id" header="NSU"></Column>
                    <Column field="status" header="Status">
                      <template #body="slot">
                        <v-chip
                            :color="statusStyle(slot.data.status)"
                            text-color="white"
                        >
                          {{ slot.data.status.toLowerCase() }}
                        </v-chip>
                      </template>
                    </Column>
                    <Column field="tipo" header="Tipo">
                      <template #body="slot">
                        {{ TIPO_TRANSACAO[slot.data.tipo] }}
                      </template>
                    </Column>
                    <Column field="vencimento_boleto" header="Vencimento">
                      <template #body="slot">
                        {{
                          slot.data.vencimento_boleto
                              | formatDate({ dateStyle: "short" })
                        }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column field="nota_fiscal" header="NFS-e">
                      <template #body="slot">
                        <Button
                            icon="pi pi-file"
                            v-if="slot.data.nota_fiscal && slot.data.nota_fiscal.url"
                            @click="openUrl(slot.data.nota_fiscal.url)"
                            class="p-button-rounded p-button-outlined p-button-secondary"
                        />
                        <Button
                            icon="pi pi-file"
                            disabled
                            v-else
                            class="p-button-rounded p-button-outlined p-button-secondary"
                        />
                      </template>
                    </Column>
                    <Column header="Enviar e-mail">
                      <template #body="slot">
                        <Button
                            icon="pi pi-send"
                            @click="enviarEmail(slot.data)"
                            class="p-button-rounded p-button-outlined p-button-secondary"
                        />
                      </template>
                    </Column>
                    <Column header="Ver">
                      <template #body="slot">
                        <Button
                            icon="pi pi-search"
                            @click="abrir(slot.data)"
                            class="p-button-rounded p-button-outlined"
                        />
                      </template>
                    </Column>
                  </DataTable>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>

      <div v-if="cobranca.status === 'CANCELADO'">
        <div class="container" style="height: 70vh">
          <div class="centralizada">
            <div>
              <img
                  class="emote-cobranca"
                  :src="require(`@/assets/img/cancelado.png`)"
              />
            </div>
            <div>
              <b class="text-cobranca-cancelado">
                <span>Essa cobrança está cancelada.</span><br>
                <span>Contate o emissor!</span>
              </b>
            </div>
          </div>
        </div>
      </div>

      <div v-if="cobranca.status !== 'QUITADO' && cobranca.status !== 'REGULAR' && cobranca.status !== 'CANCELADO'">
        <v-row class="mx-auto">
          <v-col cols="12">
            <v-card elevation="0" outlined class="py-0 px-0 billing-container">
              <span class="billing-title text-caption">Emissor</span>
              <v-row dense>
                <v-col class="coluna-dados-emissor" cols="12" xl="10" lg="10" md="10" sm="12" >
                  <v-card-title class="text-caption"><b>{{ cobranca.empresa.razao_social }}</b></v-card-title>
                  <v-card-subtitle class="text-caption">
                    <span>{{ $masks.cnpj(cobranca.empresa.cnpj) }}</span><br>
                    <span>{{ cobranca.empresa.email }} | {{ $masks.telefone(cobranca.empresa.telefone) }}</span><br>
                    <span>{{ cobranca.empresa.enderecoCompleto }}</span>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2" sm="12" class="text-end mt-4 coluna-dados-validade">
                  <v-card-title>
                    <v-text-field
                        label="Validade"
                        v-model="cobranca.data_limite_pagamento"
                        type="date"
                        dense
                        outlined
                        disabled
                    />
                  </v-card-title>
                </v-col>
                <v-col class="coluna-linha-divisoria d-flex justify-center" cols="12">
                  <div>
                    <v-divider class="divider"></v-divider>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-auto mt-n13">
          <v-col cols="12">
            <v-card elevation="0" outlined class="py-3 billing-container">
              <span class="billing-title text-caption">Itens</span>
              <v-row class="card-itens mt-2">
                <v-col>
                  <DataTable
                      :value="cobranca.itens"
                      responsiveLayout="stack"
                      breakpoint="960px"
                      scrollHeight="flex-row"
                  >
                    <Column field="quantidade" header="QTD" />
                    <Column header="Item">
                      <template #body="slot">
                        <div v-if="slot.data.servico">
                          <p>{{ slot.data.servico.sku }} - {{ truncateNomeItem(slot.data.servico.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.servico.descricao) }}</p>
                        </div>
                        <div v-if="slot.data.produto">
                          <p>{{ slot.data.produto.codigo_barras }} - {{ truncateNomeItem(slot.data.produto.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.produto.descricao) }}</p>
                        </div>
                      </template>
                    </Column>
                    <Column header="Valor">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{
                          (slot.data.quantidade * slot.data.valor)
                              | formatMoney({ minimumFractionDigits: 2 })
                        }}
                      </template>
                    </Column>
                  </DataTable>
                </v-col>
              </v-row>
              <v-row class="mx-auto" dense>
                <v-col>
                  <div
                      style="border-top: 1px solid gray"
                      class="text-end mt-4"
                  >
                                          <span class="text-caption green--text" style="display: inline-block;"
                                          >R$</span
                                          >
                    <span class="text-h3 green--text">{{
                        totalItens | formatMoney({ minimumFractionDigits: 2 })
                      }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="estaValidoParaPagamento" class="mx-auto mt-n13">
          <v-col cols="12">
            <v-card elevation="0" outlined class="py-3 billing-container">
              <span class="billing-title text-caption">Formas de Pagamento</span>
              <v-row class="mx-auto">
                <v-col cols="12">
                  <v-radio-group v-model="formaDePagamento">
                    <div class="d-flex justify-center div-formas-pagamento">
                      <div class="card-forma-pagamento border-forma-pagamento d-flex justify-center align-center" v-if="ativaPagamentoCom('BOLETO')" @click="handleChangeFormaDePagamento('BOLETO')">
                        <div>
                          <i class="fa fa-barcode"></i>
                          <span class="titulo"> Boleto </span>

                          <v-row dense>
                            <v-col>
                    <span class="text-caption">
                      Vencimento:
                      {{
                        cobranca.data_vencimento_boleto
                            | formatDate({ dateStyle: "short" })
                      }}
                    </span>
                              <span class="text-caption">
                      Max Parcelas: {{ cobranca.max_parcelas_boleto }}
                    </span>
                              <span class="d-flex justify-center mt-3">
                        <v-radio value="BOLETO"></v-radio>
                    </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div class="card-forma-pagamento border-forma-pagamento d-flex justify-center align-center" v-if="ativaPagamentoCom('PIX')" @click="handleChangeFormaDePagamento('PIX')">
                        <div>
                          <i class="fa fa-qrcode"></i>
                          <span class="titulo"> PIX </span>
                          <v-row dense>
                            <v-col>
                    <span class="text-caption"
                    >A validade do QR Code gerado será até as 23h do mesmo
                      dia.</span>
                              <span class="d-flex justify-center mt-3">
                        <v-radio value="PIX"></v-radio>
                    </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div class="card-forma-pagamento d-flex justify-center align-center" v-if="ativaPagamentoCom('CARTAO')" @click="handleChangeFormaDePagamento('CARTAO')">
                        <div>
                          <i class="pi pi-credit-card"></i>
                          <span class="titulo"> Crédito </span>

                          <v-row dense>
                            <v-col>
                    <span class="text-caption">
                      Max Parcelas : {{ cobranca.max_parcelas_cartao }}
                    </span>
                              <span class="d-flex justify-center mt-3">
                        <v-radio value="CARTAO"></v-radio>
                    </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row class="mx-auto mt-n9">
                <v-col class="d-flex justify-center">
                  <v-btn
                      color="green"
                      width="100%"
                      max-width="20rem"
                      class="white--text mb-3"
                      @click="handlePagar"
                  >
                    PAGAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <!----------------------- Dialogs ---------------------->

    <v-overlay :value="showFormCartao" v-if="showFormCartao">
      <transition name="bounce">
        <CardForm
            :form-data="cartao"
            :cliente="cobranca.cliente"
            @cartao="cartaoInformado"
            :maxParcelas="cobranca.max_parcelas_cartao"
            v-show="showFormCartao"
            @cancela-pagamento-cartao="closeFormCartao"
        />
      </transition>
    </v-overlay>

    <DialogGeraBoleto
        :cobranca="cobranca"
        v-if="showPagamentoBoleto"
        :showDialog="showPagamentoBoleto"
        :cancela="() => (showPagamentoBoleto = false)"
        @concluido="boletoGerado"
    />

    <DialogDadosClientePagamento
        v-if="showDialogDadosCliente"
        titulo="Dados para Gerar Qr Code"
        :show="showDialogDadosCliente"
        :cliente="cobranca?.cliente"
        @dadosCliente="clienteInformado"
        @fechar-dialog="(showDialogDadosCliente = false)"
    />

    <v-dialog v-model="showPagamentoPix" max-width="400" persistent>
      <v-card v-if="transacao">
        <v-card-title class="d-flex justify-space-between">
          PIX
          <v-btn fab small text @click="showPagamentoPix = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col style="display: flex;justify-content: center;">
              <VueQr
                  :text="transacao.qrcode"
                  :size="320"
              ></VueQr>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="display: flex;justify-content: center;">
              <span>
                Scaneie o QR Code ou copie o link.
              </span>
            </v-col>
          </v-row>

          <v-card-actions>
            <Button
                icon="pi pi-qrcode"
                label="Clique aqui para copiar o link"
                @click="copiaLinkPix"
                style="width: 100%"
                class="p-button-rounded p-button-outlined"
            />
          </v-card-actions>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" top right>
      Link copiado para a área de transferência
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CardForm from "@/components/Cartao/CardForm.vue";
import DialogGeraBoleto from "@/components/Dialogs/DialogGeraBoleto.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import VueQr from "vue-qr";
import DialogDadosClientePagamento from "@/components/Dialogs/DialogDadosClientePagamento.vue";

export default {
  name: "PagamentoCobranca",
  components: {
    CardForm,
    DataTable,
    Column,
    Button,
    VueQr,
    DialogGeraBoleto,
    DialogDadosClientePagamento
  },
  props: {
    id: String,
  },
  data() {
    return {
      loadingSkeleton: true,
      cobranca: {
        id: null,
        opcoes_pagamento: [],
      },
      formaDePagamento: null,
      cartao: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        cardNumberNotMask: "",
        parcels: 1,
      },
      nota: {},
      showNota: false,
      showFormCartao: false,
      showPagamentoBoleto: false,
      showPagamentoPix: false,
      transacao: null,
      snackbar: false,
      showDialogDadosCliente: false,
    };
  },

  mounted() {
    this.getCobrancaById();
  },
  computed: {
    ...mapState(["config"]),
    totalItens() {
      if (!this.cobranca) return 0;
      const total = this.cobranca.itens.reduce(
        (prev, curr) => (prev += curr.quantidade * curr.valor),
        0
      );
      return total.toFixed(2);
    },
    getLogoImage() {
      if (!this.config.images.logo) return;
      return this.config.images.logo;
    },
    getSecondaryColor() {
      if (!this.config.colors.secondary) return;
      return this.config.colors.secondary;
    },
    estaValidoParaPagamento() {
      if (!this.cobranca) return false;

      if (this.cobranca.forma_pagamento) {
        return false;
      }

      if (
        this.cobranca.status == "CANCELADO" ||
        this.cobranca.status == "EXPIRADO"
      ) {
        return false;
      }

      const hoje = new Date(new Date().toJSON().split("T")[0]);
      const limite = new Date(
        this.cobranca.data_limite_pagamento.split("T")[0]
      );

      return hoje <= limite;
    },
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm",
    ]),

    truncateNomeItem(nome) {
      if (nome.length > 260) {
        return nome.substring(0, 260) + '...';
      } else {
        return nome;
      }
    },

    getCobrancaById() {
      this.$axios
        .get(`/public/cobranca/${this.id}`)
        .then((response) => {
          this.cobranca = response.data;
          this.cobranca.data_limite_pagamento = this.cobranca.data_limite_pagamento.substring(0, 10);
          if(this.cobranca.forma_pagamento == 'PIX' &&
              this.cobranca.status != 'PAGO'&&
              this.cobranca.status != 'CANCELADO'){
            setTimeout(this.verificarPagamentoPix, 30000)
          }
          this.loadingSkeleton = false;
        })
        .catch(() => {
          this.$router.push({ name: "TemaNaoConfigurado" });
        });
    },
    cartaoInformado() {
      const obj = {
        number: this.cartao.cardNumberNotMask.replaceAll(" ", ""),
        holder_name: this.cartao.cardName,
        expires: this.cartao.cardMonth
          .toString()
          .concat(this.cartao.cardYear.toString().substring(2, 4)),
        cvv: this.cartao.cardCvv,
        parcels: this.cartao.parcels,
      };

      const data = {
        forma_pagamento: "CARTAO",
        cartao: {
          holder_name: this.cartao.cardName,
          expires: this.cartao.cardMonth
            .toString()
            .concat(this.cartao.cardYear.toString().substring(2, 4)),
          number: this.cartao.cardNumberNotMask.replaceAll(" ", ""),
          cvv: this.cartao.cardCvv,
        },
        parcelas: this.cartao.parcels,
        cliente: JSON.stringify(this.cartao.cliente)
      };
      this.$axios
        .post(`cobranca/${this.cobranca.id}/pagar`, data)
        .then(() => {
          this.closeFormCartao();

          this.getCobrancaById();
        })
        .catch((error) => {
          if (error.response.data.falha) {
            this.openDialogMessage({
              type: "error",
              title: `Pagamento ${error.response.data.status}`,
              text: `${error.response.data.id}: ${error.response.data.falha}`,
            });
          } else {
            this.openDialogMessage({
              type: "error",
              title: `Falha na transação`,
              text: `${error.response.data}`,
            });
          }
        });
    },
    async pagamentoPix() {
      this.closeDialogConfirm();

      const data = {
        forma_pagamento: "PIX",
        parcelas: 1,
        cliente: JSON.stringify(this.cobranca.cliente)
      };

      await this.$axios
        .post(`cobranca/${this.cobranca.id}/pagar`, data)
        .then((response) => {
          this.cobranca = response.data;
          this.abrir(this.cobranca.transacoes[0]);
          setTimeout(this.verificarPagamentoPix,30000)
        })
        .catch((error) => {
          if (error.response.data.falha) {
            this.openDialogMessage({
              type: "error",
              title: `Pagamento ${error.response.data.status}`,
              text: `${error.response.data.id}: ${error.response.data.falha}`,
            });
          } else {
            this.openDialogMessage({
              type: "error",
              title: `Falha ao gerar o PIX`,
              text: `${error.response.data}`,
            });
          }
        });
    },
    async verificarPagamentoPix() {
      this.cobranca.transacoes
      .filter(obj => obj.tipo == 'PIX' && obj.status == 'PENDENTE')
      .forEach(async obj => {
        await this.$axios.get(`/transacao/qr/${obj.id}`)
      });

      this.showPagamentoPix = false;
      this.getCobrancaById();
    },
    escolhaPix() {

      if(this.cobranca.cliente) {
        this.openDialogConfirm({
          title: "Pagar com PIX",
          text: `Confirma a escolha por pagar com PIX ? A forma de pagamento não poderá ser alterada.`,
          confirma: this.pagamentoPix,
          cancela: this.closeDialogConfirm
        });
      } else {
        this.showDialogDadosCliente = true;
      }

    },
    copiaLinkPix() {
      const copyValue = this.transacao.qrcode;
      navigator.clipboard.writeText(this.transacao.qrcode);
      this.snackbar = true;
    },
    boletoGerado() {
      this.showPagamentoBoleto = false;
      this.emailsBoleto = [];
      this.currentEmail = null;
      this.getCobrancaById();
    },
    closeFormCartao() {
      this.cartao = {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        cardNumberNotMask: "",
        parcels: 1,
      };

      this.showFormCartao = false;
    },
    ativaPagamentoCom(metodo) {
      if (!this.cobranca.id) return false;

      return this.cobranca.opcoes_pagamento.filter((forma) => forma == metodo)
        .length;
    },
    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir o boleto");
      }
    },
    abrir(transacao) {
      console.log("Transação", transacao)
      this.transacao = transacao;

      if (this.transacao.tipo == "BOLETO") {
        this.openUrl(this.transacao.url_boleto);
        return;
      }

      if (this.transacao.tipo == "PIX") {
        this.showPagamentoPix = true;
        return;
      }
    },
    handlePagar() {

      if(!this.formaDePagamento) {
        this.$toast.add({
          severity: "error",
          summary: "Escolha a forma de pagamento!",
          life: 2000
        });
        return;
      }

      if (this.formaDePagamento === "BOLETO") this.showPagamentoBoleto = true;
      if (this.formaDePagamento === 'CARTAO') this.showFormCartao = true;
      if (this.formaDePagamento === 'PIX') this.escolhaPix();
    },

    handleChangeFormaDePagamento(forma) {
      this.formaDePagamento = forma;
    },

    abrirTransacao(id) {
      const url = {
        name: "DetalheTransacao",
        params: {
          idTransacao: id.toString(),
          callback: "cobranca"
        }
      };
      this.$router.push(url);
    },

    enviarEmail(obj) {
      this.$axios
          .post(`/transacao/${obj.id}/enviar-email`)
          .then(() => {
            this.openDialogMessage({
              type: "success",
              title: "Sucesso",
              text: "E-mail enviado!"
            });
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Enviar e-mail",
              text: error.response.data
            });
          });
    },

    clienteInformado(cliente) {

      if(cliente) {
        this.showDialogDadosCliente = false;
        this.cobranca.cliente = cliente;

        this.openDialogConfirm({
          title: "Pagar com PIX",
          text: `Confirma a escolha por pagar com PIX ? A forma de pagamento não poderá ser alterada.`,
          confirma: this.pagamentoPix,
          cancela: this.closeDialogConfirm
        });

      }
    },

  },
};
</script>

<style scoped>
.container--fluid {
  padding: 2rem;
}

.custom-card {
  border-left: 2px solid black;
  padding: 10px;
}
.logo {
  width: 100%;
  height: 100px;
}
.logo img {
  width: 200px;
  height: auto;
  margin-top: 22px;
}

.status-container {
  width: 100px;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.text-caption {
  display: block;
}

.titulo {
  font-size: 1.3rem;
}

i {
  font-size: 1.3rem;
}

.billing-container {
  padding: 10px;
  position: relative;
  margin: 20px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.card-itens {
  max-height: 260px;
  overflow-y: auto;
}

.card-itens::-webkit-scrollbar {
  width: 8px;
  background-color: #e1e1e1;
}

.card-itens::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 10px;
}

.card-forma-pagamento {
  width: 220px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
}

.border-forma-pagamento {
  border-right: 1px solid #e1e1e1;
}

.coluna-linha-divisoria {
  display: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centralizada {
  width: 80%;
  text-align: center;
}

.text-cobranca {
  color: #656565;
}

.text-cobranca-cancelado {
  font-size: 28px;
}

.emote-cobranca {
  width: 100px;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 959px) {
  .coluna-linha-divisoria {
    display: block;
  }

  .coluna-linha-divisoria div {
    width: 90%;
    margin-top: -115px;
  }

  .logo div {
    display: flex;
    justify-content: center;
  }



}

@media (max-width: 500px) {
  .text-cobranca-cancelado {
    font-size: 15px;
  }

}

@media (max-width: 620px) {
  .div-formas-pagamento {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .border-forma-pagamento {
    border-bottom: 1px solid #e1e1e1;
    border-right: none;
  }

  .card-forma-pagamento {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .card-forma-pagamento div {
    width: 200px;
  }


}


</style>
