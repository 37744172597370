import axios from "axios";
import store from "@/store";
import CryptoJS from "crypto-js";

const messageAndLogout = (message: string): void => {
  store.commit("openDialogMessage", {
    type: "error",
    title: "Erro de Autenticação",
    text:message,
  });
  store.commit("logout");
}
const normalizeUrl = (url: string, params: object): string => {
  let paramsConverted = "";
  if (params) {
    paramsConverted = "?";
    Object.entries(params).forEach(([key, value]) => {
      paramsConverted += `${key}=${value}&`;
    });
    paramsConverted = paramsConverted.substring(0, paramsConverted.length - 1);
  }
  return `${url}${paramsConverted}`;
};

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

baseApi.interceptors.request.use(
  (config) => {

    
    if (!config.headers["noloading"]) {
      store.commit("setAppLoading", true);
    }

    const user = store.state.user;

    //TODO router.push tela de login
    if (!user.token) return config;

    if(user.tipo !== "USUARIO") {
      messageAndLogout("Usuários de aplicação não são autorizados a utilizar a plataforma web, por favor use um usuário comum.");
    }
    let key;
    if (!config.data) {
      key = normalizeUrl(`${config.baseURL}${config.url}`, config.params);
    } else {
      key = JSON.stringify(config.data);
    }

    if (config.headers["file-token"]) {
      key = config.headers["file-token"];
    }

    const pass = "sha1=" + CryptoJS.HmacSHA1(key, user.token).toString();

    config.headers.Authorization = "Basic " + btoa(user.email + ":" + pass);

    return config;
  },
  (error) => {
    store.commit("setAppLoading", false);
    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  (response) => {
    store.commit("setAppLoading", false);
    return response;
  },

  (error) => {
     console.log(error)
    store.commit("setAppLoading", false);

    // if (error.message === "Request failed with status code 401") {
    //   messageAndLogout("Houve um problema na autenticação da sua requisição, você será redirecionado para a tela de login.");
    // }

    return Promise.reject(error);
  }
);

export default baseApi;
