<template>
  <div>
    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="3">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="3">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader
              :loading="true"
              type="image@2"
              class="py-3"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader
              :loading="true"
              type="table"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid>
      <v-row class="dash-tab" dense>
        <v-col>
          <DashboardCardSaldo />
        </v-col>
        <v-col>
          <DashboardCardRecebiveis />
        </v-col>
        <v-col>
          <DashboardCardVendas />
        </v-col>
        <v-col>
          <DashboardCardProjecoes />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <GraficoVendas />
        </v-col>
        <v-col>
          <TableTransacoes
              title="Últimas Transações"
              :transacoes="ultimasTransacoes"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardCardRecebiveis from "@/components/Cards/DashboardCardRecebiveis.vue";
import DashboardCardVendas from "@/components/Cards/DashboardCardVendas.vue";
import DashboardCardSaldo from "@/components/Cards/DashboardCardSaldo.vue";
import DashboardCardProjecoes from "@/components/Cards/DashboardCardProjecoes.vue";
import TableTransacoes from "@/components/Tables/TableTransacoes.vue";
import GraficoVendas from "@/components/Charts/GraficoVendas.vue";
import { mapState } from "vuex";

export default {
  components: {
    DashboardCardRecebiveis,
    DashboardCardVendas,
    DashboardCardSaldo,
    DashboardCardProjecoes,
    TableTransacoes,
    GraficoVendas
  },
  data() {
    return {
      loadingSkeleton: true,
      ultimasTransacoes: []
    };
  },
  computed: {
    ...mapState(["empresaAtual", "config"]),
    cardRecebiveisColor() {
      return this.config.colors.cards.recebiveis;
    },
    cardVendasColor() {
      return this.config.colors.cards.vendas;
    },
    cardEstornosColor() {
      return this.config.colors.cards.estornos;
    },
    cardFalhasColor() {
      return this.config.colors.cards.falhas;
    }
  },
  beforeMount() {
    if (!this.empresaAtual && !this.empresaAtual.id) {
      this.$store.commit("showEmpresasDialog", true);
    }
  },
  mounted() {
    this.getUltimasTransacaoes();
  },
  methods: {
    getUltimasTransacaoes() {
      if (!this.empresaAtual || !this.empresaAtual.id) return;
      this.$axios
        .get(`/transacao/empresa/${this.empresaAtual.id}/`, {
          params: {
            limit: 5,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.ultimasTransacoes = response.data.data;
          this.loadingSkeleton = false;
        });
    }
  }
};
</script>

<style scoped>
.dash-tab {
  animation: moveDown 0.6s ease-in-out forwards;
}

@keyframes moveDown {
  0% {
    transform: translateY(-200px);
  }

  100% {
    transform: translateY(0px);
  }
}
@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
