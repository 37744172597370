<template>
  <div>

    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>

      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@15"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-3">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid>
      <form @submit.prevent="handleSave">
        <v-row style="padding: 2rem;">
          <v-col cols="6">
            <v-row dense>
              <v-col cols="2">
                <v-combobox
                    label="Status"
                    v-model="cliente.status"
                    dense
                    :items="['ATIVO', 'INATIVO', 'BLOQUEADO']"
                >
                </v-combobox>
              </v-col>
              <v-col cols="3">
                <v-select
                    dense
                    :items="TIPO_PESSOA"
                    item-text="texto"
                    item-value="valor"
                    v-model="cliente.tipo"
                    :readonly="!isDocumentEditable"
                    @change="handleChangeTipoPessoa"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    v-if="cliente.tipo === 'PESSOA_FISICA'"
                    required
                    dense
                    :readonly="!isDocumentEditable"
                    v-model="cliente.cpf"
                    @keyup="cliente.cpf = $masks.cpf(cliente.cpf)"
                    @blur="checkCpfCnpj(cliente.cpf, 'cpf')"
                    label="CPF"
                    :maxlength="14"
                    :error-messages="errors.cpf"
                >
                </v-text-field>
                <v-text-field
                    v-if="cliente.tipo === 'PESSOA_JURIDICA'"
                    :readonly="!isDocumentEditable"
                    required
                    dense
                    v-model="cliente.cnpj"
                    @keyup="cliente.cnpj = $masks.cnpj(cliente.cnpj)"
                    label="CNPJ"
                    @blur="checkCpfCnpj(cliente.cnpj, 'cnpj')"
                    :maxlength="18"
                    :error-messages="errors.cnpj"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-switch
                    :disabled="mode === 'view'"
                    v-model="cliente.pep"
                    label="PEP ou Orgão Publico"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row v-if="cliente.tipo === 'PESSOA_JURIDICA'">
              <v-col cols="4">
                <v-text-field
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.inscricao_municipal"
                    label="Inscrição Municipal"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.inscricao_estadual"
                    label="Inscrição Estadual"
                />
              </v-col>
            </v-row>
            <v-row v-if="cliente.tipo === 'PESSOA_JURIDICA'">
              <v-col>
                <v-text-field
                    required
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.razao_social"
                    label="Razão Social"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    required
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.nome"
                    label="Nome"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.email"
                    label="Email"
                    type="email"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    required
                    dense
                    v-on:input="
                  cliente.telefone_celular = $masks.telefone(
                    cliente.telefone_celular
                  )
                "
                    :readonly="mode == 'view'"
                    v-model="cliente.telefone_celular"
                    label="Telefone Celular"
                    :maxLength="13"
                >
                </v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                    dense
                    v-on:input="
                  cliente.telefone_fixo = $masks.telefone(cliente.telefone_fixo)
                "
                    :readonly="mode == 'view'"
                    v-model="cliente.telefone_fixo"
                    label="Telefone Fixo"
                    :maxLength="13"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <v-text-field
                    dense
                    @keyup="cliente.cep = $masks.cep(cliente.cep)"
                    :readonly="mode == 'view'"
                    v-model="cliente.cep"
                    @blur="consultaCEP"
                    label="Cep"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.endereco"
                    label="Endereço"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                    id="numero"
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.numero"
                    label="Número"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.bairro"
                    label="Bairro"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.complemento"
                    label="Complemento"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.cidade"
                    label="Cidade"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.uf"
                    label="UF"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.codigo_ibge"
                    label="COD. IBGE"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-textarea
                  outlined
                  counter="255"
                  :readonly="mode == 'view'"
                  label="Observações do cliente"
                  v-model="cliente.observacoes"
              >
              </v-textarea>
            </v-row>

            <v-row>
              <v-col class="text-end mr-15">
                <v-btn
                    dark
                    color="primary"
                    v-if="mode != 'view' && user.permissoes.adm_cliente"
                    type="submit"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="mt-n8">
            <v-row v-if="carteira && !eventoSelecionado">
              <v-col cols="12">
                <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
                  <span class="billing-title text-caption">Carteira</span>
                  <v-row class="mx-auto" dense>
                    <v-col>
                      <v-card-title class="text-caption"><b>{{ carteira.id }}</b></v-card-title>
                      <v-card-subtitle>{{ carteira.tipo }}</v-card-subtitle>
                    </v-col>
                    <v-col class="text-end">
                      <v-card-subtitle>{{ carteira.tag }}</v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col v-if="eventos" cols="12" class="mt-n12">
                <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
                  <span class="billing-title text-caption">Eventos</span>
                  <v-row class="mx-auto" dense>
                    <v-col cols="6" v-for="(evento, index) in eventos" :key="index">
                      <v-card style="cursor:pointer;" outlined class="mt-3 px-1 py-1 mb-3" @click="handleCLickEvento(evento)">
                        <v-row class="mx-auto" dense>
                          <v-col class="d-flex justify-center">
                            <v-img
                              :src="evento.foto_url"
                              width="20px"
                              contain
                            ></v-img>
                          </v-col>
                        </v-row>
                        <v-row class="mx-auto" dense>
                          <v-col>
                            <v-card-title class="text-caption d-flex justify-space-between">
                              <span class="text-start"><b>{{ evento.nome }}</b></span>
                              <span class="text-end">
                                              {{
                                  evento.data_realizacao
                                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                                }}
                              </span>
                            </v-card-title>
                            <v-card-subtitle>{{ evento.status }}</v-card-subtitle>
                            <v-card-text> {{ evento.descricao }} </v-card-text>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="eventoSelecionado" class="flex-wrap">
              <v-col cols="6" class="d-flex">
                <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
                  <span class="billing-title text-caption">Evento</span>
                  <v-row class="mx-auto" dense>
                    <v-col>
                      <v-card-title class="text-caption d-flex justify-space-between">
                        <span class="text-start"><b>{{ eventoSelecionado.nome }}</b></span>
                        <span class="text-end">
                                              {{
                            eventoSelecionado.data_realizacao
                                | formatDate({ dateStyle: "short", timeStyle: "short" })
                          }}
                              </span>
                      </v-card-title>
                      <v-card-subtitle>{{ eventoSelecionado.status }}</v-card-subtitle>
                      <v-card-text> {{ eventoSelecionado.descricao }} </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6" class="d-flex">
                <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
                  <span class="billing-title text-caption">Saldo</span>
                  <v-row class="mx-auto" :class="eventoSelecionado.descricao ? 'mt-3' : 'mt-1'" dense>
                    <v-col>
                      <v-card-text
                          class="d-flex justify-center align-center"
                      >
                  <span class="ml-5 caption font-weight-bold green--text"
                  >R$</span
                  >
                        <span class="text-h3 green--text font-weight-bold">{{
                            saldoEvento
                                | formatMoney({ minimumFractionDigits: 2 })
                          }}</span>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" class="mt-n12">
                <v-card elevation="0" outlined class="py-0 px-0 billing-container">
                  <span class="billing-title text-caption">Extratos</span>
                  <v-row class="mx-auto mt-4" dense>
                    <v-col>
                      <div class="data-table-container">
                        <DataTable
                            :value="extratosEvento"
                            responsiveLayout="stack"
                            breakpoint="960px"
                            scrollHeight="flex-row"
                        >
                          <Column field="data_operacao" header="Data">
                            <template #body="slot">
                              {{
                                slot.data.data_operacao
                                    | formatDate({
                                  day: "2-digit",
                                  month: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit"
                                })
                              }}
                            </template>
                          </Column>

                          <Column field="tipo" header="Tipo">
                            <template #body="slot">
                              {{ TIPO_EXTRATO[slot.data.tipo].texto }}
                            </template>
                          </Column>

                          <Column field="descricao" header="Descricao">
                            <template #body="slot">
                              {{ slot.data.descricao }}
                            </template>
                          </Column>

                          <Column field="valor" header="Valor">
                            <template #body="slot">
                    <span
                        :style="
                        slot.data.valor >= 0 ? 'color:green' : 'color:red'
                      "
                    >R$
                      {{
                        slot.data.valor
                            | formatMoney({ minimumFractionDigits: 2 })
                      }}</span
                    >
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="text-end mt-n9">
                <v-btn small color="primary" dark @click="selecionarOutroEvento">
                  Selecionar outro evento
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { colors } from 'vuetify/lib';

export default {
  props: {
    mode: String,
    idCliente: String,
    backRoute: String,
    idEmpresa: String
  },
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      loadingSkeleton: false,
      tipo_cliente: "NAO_INFORMADO",
      cliente: {
        status: "ATIVO",
        tipo: "PESSOA_FISICA",
        telefone_fixo: "",
        telefone_celular: ""
      },
      carteira: null,
      eventos: null,
      eventoSelecionado: null,
      saldoEvento: 0,
      extratosEvento: null,
      errors: {
        cpf: null,
        cnpj: null,
      },
      status: [
        {
          texto: "VENDA ABERTA",
          valor: "VENDA_ABERTA"
        }
      ],
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    isDocumentEditable(){
      return this.mode === "create" || (this.mode === "edit" && this.tipo_cliente === 'NAO_INFORMADO')
    }
  },
  mounted() {
    if (this.mode != "create") this.getCliente();

    if (this.idEmpresa) {
      this.cliente.empresa_id = this.idEmpresa;
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    async handleSave() {

      if(Object.values(this.errors).every(value => value === null)) {
        this.mode === "create" ? await this.save() : await this.edit();
      }

    },
    async save() {
      delete this.cliente.documento;
      delete this.cliente.primeiroNome;
      delete this.cliente.ultimoNome;

      await this.$axios
        .post("/cliente/", this.cliente)
        .then(response => {
          this.$toast.add({
            severity: "success",
            summary: `Cliente cadastrado com sucesso.`,
            life: 2000
          });
          this.$router.push({
            name: this.backRoute ? this.backRoute : "Clientes",
            params: {
              cliente: response.data
            }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Salvar Cliente",
            text: error.response.data
          });
        });
    },
    async edit() {
      delete this.cliente.documento;
      delete this.cliente.primeiroNome;
      delete this.cliente.ultimoNome;
      this.cliente.id = this.idCliente;
      await this.$axios
        .put("/cliente/", this.cliente)
        .then(response => {
          this.$toast.add({
            severity: "success",
            summary: `Cliente atualizado com sucesso.`,
            life: 2000
          });
          this.$router.push({
            name: this.backRoute ? this.backRoute : "Clientes",
            params: {
              cliente: response.data
            }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Cliente",
            text: error.response.data
          });
        });
    },
    async getCarteira() {
      await this.$axios
        .get(`/cliente/${this.cliente.id}/carteira`)
        .then(response => {
          this.carteira = response.data;
          this.getEventos();
        })
        .catch(error => {
          this.$toast.add({
            severity: "error",
            summary: error.response.data,
            life: 2000
          });
        });
    },
    async getEventos() {
      this.$axios
        .get(`/evento/empresa/${this.empresaAtual.id}/listar`, {
          params: {
            first: 0,
            limit: 9999,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.eventos = response.data.data;

          this.eventos = this.eventos.map((element) => {
            const status = this.status.filter((item) => {
              return item.valor === element.status
            });

            if(status.length === 1) {
              element.status = status[0].texto;
            }

            return element;
          });

        });
    },
    getCliente() {
      this.loadingSkeleton = true;

      this.$axios
        .get(`/cliente/${this.idCliente}`)
        .then(response => {
          this.cliente = response.data;
          this.tipo_cliente = this.cliente.tipo;

          if (this.cliente.cpf) {
            this.cliente.cpf = this.$masks.cpf(this.cliente.cpf);
          }
          if (this.cliente.cnpj) {
            this.cliente.cnpj = this.$masks.cnpj(this.cliente.cnpj);
          }

          if (this.cliente.telefone_fixo) {
            this.cliente.telefone_fixo = this.$masks.telefone(
              this.cliente.telefone_fixo
            );
          }
          if (this.cliente.telefone_celular) {
            this.cliente.telefone_celular = this.$masks.telefone(
              this.cliente.telefone_celular
            );
          }
          if (this.cliente.cep) {
            this.cliente.cep = this.$masks.cep(this.cliente.cep);
          }

          this.getCarteira();

          this.loadingSkeleton = false;

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Cliente",
            text: error.response.data
          });
        });
    },

    handleChangeTipoPessoa() {
      this.errors.cpf = null;
      this.errors.cnpj = null;
    },

    checkCpfCnpj(value, tipo) {
      if(value && this.mode === "create") {

        this.errors.cpf = null;
        this.errors.cnpj = null;

        if(tipo === 'cpf' && value.length !== 14) {
          this.errors.cpf = "CPF inválido!";
          return;
        }

        if(tipo === 'cnpj' && value.length !== 18) {
          this.errors.cnpj = "CNPJ inválido!";
          return;
        }

        this.$axios
            .get(`/cliente/empresa/${this.empresaAtual.id}/listar`, {
              headers: {
                filtros: JSON.stringify({
                  cliente: [value]
                })
              },
            })
            .then(response => {
              if(response.data.data.length > 0) {

                if(tipo === 'cpf') {
                  this.errors.cpf = "Já existe cliente com esse CPF!";
                }

                if(tipo === 'cnpj') {
                  this.errors.cnpj = "Já existe cliente com esse CNPJ!";
                }

              }

            })
            .catch(error => {
              this.openDialogMessage({
                type: "error",
                title: "Erro - Consultar Clientes",
                text: error.response.data
              });
            });

      }
    },
    consultaCEP() {
      if (!this.cliente.cep) return;
      this.getCEP(this.cliente.cep)
        .then(response => {
          this.cliente = Object.assign({}, this.cliente, response);
          const number = document.getElementById('numero');
          number.focus();
        })
        .catch(() => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar CEP",
            text: "CEP não encontrado."
          });
        });
    },
    handleCLickEvento(item) {
      this.eventoSelecionado = item;
      this.getSaldoEvento(item.id);
      this.getExtratoEvento(item.id);
    },
    async getSaldoEvento(id) {
      await this.$axios
        .get(`/cliente/${this.cliente.id}/evento/${id}/saldo`)
        .then(response => {
          this.saldoEvento = response.data.saldo;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha - Consultar Saldo Evento",
            text: error.response.data
          });
        });
    },
    async getExtratoEvento(id) {
      await this.$axios
        .get(`/cliente/${this.cliente.id}/evento/${id}/extrato`)
        .then(response => {
          this.extratosEvento = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha - Consultar Saldo Evento",
            text: error.response.data
          });
        });
    },

    selecionarOutroEvento() {
      this.eventoSelecionado = null;
      this.saldoEvento = 0;
      this.extratosEvento = null;
    },
  }
};
</script>

<style scoped>
.billing-container {
  padding: 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.data-table-container {
  max-height: 390px;
  overflow-y: auto;
  height: 390px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@media (max-width: 900px) {
  .col,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    flex: none;
    max-width: 25rem;
  }
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
