<template>
  <div class="mt-2">
    <v-row>
      <v-col class="d-flex justify-start">
        <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="getDados"
            :showIcon="true"
            dateFormat="dd/mm/yy"
        />
        <BotaoBuscarListas class="ml-3" @buscar="getDados" />
      </v-col>

      <v-col>
        <div class="text-end">
          <v-btn type="button" @click="exportarMonitoriamento" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else class="mx-auto mt-5" dense>
      <v-col cols="12">
        <Chart type="line" :data="grafico" :options="options" class="custom-chart" />
      </v-col>
      <v-col cols="12">
        <v-data-table hide-default-header hide-default-footer disable-pagination ref="table">

          <template v-slot:[`header`]>
            <thead>
            <tr>
              <th></th>
              <th style="background-color: #eee" class="text-center border-full" colspan="4">Vendas</th>
              <th style="background-color: #eee" class="text-center border-right border-top border-bottom" colspan="4">Recebíveis</th>
              <th style="background-color: #eee" class="text-center border-right border-top border-bottom" colspan="4">Estornos</th>
            </tr>
            <tr>
              <th class="text-center border-bottom border-top border-left">Data</th>
              <th class="text-center border-bottom border-left">Bruto</th>
              <th class="text-center border-bottom">Tx Adm</th>
              <th class="text-center border-bottom">Tx Ant</th>
              <th class="text-center border-bottom border-right">Líquido</th>
              <th class="text-center border-bottom">Bruto</th>
              <th class="text-center border-bottom">Tx Adm</th>
              <th class="text-center border-bottom">Tx Ant</th>
              <th class="text-center border-bottom border-right">Líquido</th>
              <th class="text-center border-bottom">Bruto</th>
              <th class="text-center border-bottom">Tx Adm</th>
              <th class="text-center border-bottom">Tx Ant</th>
              <th class="text-center border-bottom border-right">Líquido</th>
            </tr>
            </thead>
          </template>

          <template v-slot:[`body`]>
            <tr v-for="dado of dados" :key="dado.data">
              <td class="text-center border-bottom border-left">
                {{ dado.data | formatDate({ dateStyle: "short" }) }}
              </td>
              <td class="text-center border-left border-bottom">
                {{
                  dado.vendas.totalBruto
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-bottom">
                {{
                  dado.vendas.totalTaxaAdm
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-bottom">
                {{
                  dado.vendas.totalTaxaAnt
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-right border-bottom">
                {{
                  dado.vendas.totalLiquido
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>

              <td class="text-center border-bottom">
                {{
                  dado.recebiveis.totalBruto
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-bottom">
                {{
                  dado.recebiveis.totalTaxaAdm
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-bottom">
                {{
                  dado.recebiveis.totalTaxaAnt
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-right border-bottom">
                {{
                  dado.recebiveis.totalLiquido
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>

              <td class="text-center border-bottom">
                {{
                  dado.estornos.totalBruto
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-bottom">
                {{
                  dado.estornos.totalTaxaAdm
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-bottom">
                {{
                  dado.estornos.totalTaxaAnt
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
              <td class="text-center border-right border-bottom">
                {{
                  dado.estornos.totalLiquido
                      | formatMoney({
                    minimumFractionDigits: 2
                  })
                }}
              </td>
            </tr>
            <tr style="background-color: #4f4f4f">
              <td class="text-center white--text">-</td>
              <td class="text-center white--text"> {{ totalBrutoVendas | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalTaxaAdmVendas | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalTaxaAntVendas | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalLiquidoVendas | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalBrutoRecebiveis | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalTaxaAdmRecebiveis | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalTaxaAntRecebiveis | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalLiquidoRecebiveis | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalBrutoEstornos | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalTaxaAdmEstornos | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalTaxaAntEstornos | formatMoney({minimumFractionDigits: 2})}}</td>
              <td class="text-center white--text"> {{ totalLiquidoEstornos | formatMoney({minimumFractionDigits: 2})}}</td>
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import Chart from "primevue/chart";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Monitoramento",
  components: {
    BotaoBuscarListas,
    Calendar,
    Chart

  },
  data() {
    return {
      loadingSkeleton: false,
      dataIntervalo: [new Date(), new Date()],
      menuDataInicio: false,
      menuDataFim: false,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      dados: [],
      grafico: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalBrutoRecebiveis() {
      return this.dados.reduce((acc, item) => acc + item.recebiveis.totalBruto, 0);
    },
    totalLiquidoRecebiveis() {
      return this.dados.reduce((acc, item) => acc + item.recebiveis.totalLiquido, 0);
    },
    totalTaxaAdmRecebiveis() {
      return this.dados.reduce((acc, item) => acc + item.recebiveis.totalTaxaAdm, 0);
    },
    totalTaxaAntRecebiveis() {
      return this.dados.reduce((acc, item) => acc + item.recebiveis.totalTaxaAnt, 0);
    },

    totalBrutoVendas() {
      return this.dados.reduce((acc, item) => acc + item.vendas.totalBruto, 0);
    },
    totalLiquidoVendas() {
      return this.dados.reduce((acc, item) => acc + item.vendas.totalLiquido, 0);
    },
    totalTaxaAdmVendas() {
      return this.dados.reduce((acc, item) => acc + item.vendas.totalTaxaAdm, 0);
    },
    totalTaxaAntVendas() {
      return this.dados.reduce((acc, item) => acc + item.vendas.totalTaxaAnt, 0);
    },

    totalBrutoEstornos() {
      return this.dados.reduce((acc, item) => acc + item.estornos.totalBruto, 0);
    },
    totalLiquidoEstornos() {
      return this.dados.reduce((acc, item) => acc + item.estornos.totalLiquido, 0);
    },
    totalTaxaAdmEstornos() {
      return this.dados.reduce((acc, item) => acc + item.estornos.totalTaxaAdm, 0);
    },
    totalTaxaAntEstornos() {
      return this.dados.reduce((acc, item) => acc + item.estornos.totalTaxaAnt, 0);
    },


  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getDados() {
      this.loadingSkeleton = true;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if (!this.dataIntervalo[0] || !this.dataIntervalo[1]) {
        return;
      }
      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/monitoramento`,
          {
            params: {
              "data-inicio": this.dataInicio,
              "data-fim": this.dataFim
            }
          }
        )
        .then(response => {
          this.dados = response.data.dados;

          this.grafico.labels = this.dados.map(obj => {
            return `${obj.data.split("-")[2]}/${obj.data.split("-")[1]}`;
          });

          this.grafico.datasets = [
            {
              label: "Vendas",
              data: this.dados.map(obj => {
                return obj.vendas.totalLiquido;
              }),
              borderColor: "#FFA726",
              tension: 0.4
            },
            {
              label: "Recebíveis",
              data: this.dados.map(obj => {
                return obj.recebiveis.totalLiquido;
              }),
              borderColor: "#26ffe6",
              tension: 0.4
            },
            {
              label: "Estorno",
              data: this.dados.map(obj => {
                return obj.estornos.totalLiquido;
              }),
              borderColor: "#7b00cb",
              tension: 0.4
            }
          ];

          this.loadingSkeleton = false;
        });
    },
    exportarMonitoriamento() {
        const copy = this.dados.map(item => {
            const obj = {};
            obj.dados_data = item.data;
            obj.dados_vendas_totalBruto = item.vendas.totalBruto;
            obj.dados_vendas_totalLiquido = item.vendas.totalLiquido;
            obj.dados_vendas_totalTaxaAdm = item.vendas.totalTaxaAdm;
            obj.dados_vendas_totalTaxaAnt = item.vendas.totalTaxaAnt;
            obj.dados_recebiveis_totalBruto = item.recebiveis.totalBruto;
            obj.dados_recebiveis_totalLiquido = item.recebiveis.totalLiquido;
            obj.dados_recebiveis_totalTaxaAdm = item.recebiveis.totalTaxaAdm;
            obj.dados_recebiveis_totalTaxaAnt = item.recebiveis.totalTaxaAnt;
            obj.dados_estornos_totalBruto = item.estornos.totalBruto;
            obj.dados_estornos_totalLiquido = item.estornos.totalLiquido;
            obj.dados_estornos_totalTaxaAdm = item.estornos.totalTaxaAdm;
            obj.dados_estornos_totalTaxaAnt = item.estornos.totalTaxaAnt;

            return obj;
          });

          this.toCSVeDownload("relatorios.csv", copy);
    }
  },
  mounted() {
    this.getDados();
  }
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.border-full {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
th {
  //background-color: rgba(0, 0, 0, 0.1);
}

.custom-chart {
  height: 200px;
}

</style>
