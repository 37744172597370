import { render, staticRenderFns } from "./Boleto.vue?vue&type=template&id=11ff5562&scoped=true"
import script from "./Boleto.vue?vue&type=script&lang=js"
export * from "./Boleto.vue?vue&type=script&lang=js"
import style0 from "./Boleto.vue?vue&type=style&index=0&id=11ff5562&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ff5562",
  null
  
)

export default component.exports