<template>
    <v-container fluid>
        <v-dialog v-model="showDialog" max-width="700" persistent>
            <v-card v-if="pedido">

                <v-card-title class="d-flex justify-space-between">
                    Pedido de Antecipação
                    <v-btn fab small text @click="fechar">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row dense>
                        <v-col>Data de solicitação 
                            {{ pedido.data_criacao | formatDate({ dateStyle: "short", timeStyle: "short" }) }}
                        </v-col>
                        <v-col>Data de liquidação 
                            {{ pedido.data_criacao | formatDate({ dateStyle: "short"}) }}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <span class="d-flex">Total antecipado</span>
                            R$ {{ pedido.valor_bruto_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
                        </v-col>
                        <v-col>
                            <span class="d-flex">Total taxa de antecipação</span>
                            R$ {{ pedido.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2 })}}
                        </v-col>
                        <v-col>
                            <span class="d-flex">Valor total liquidação</span> 
                            R$ {{ pedido.valor_liquido_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <span class="d-flex">Recebedor</span>
                            <span class="d-flex">{{ pedido.recebedor.razao_social }}</span>                            
                        </v-col>
                        <v-col>
                            <span class="d-flex">Solicitante</span>
                            <span class="d-flex">{{ pedido.solicitante.nome }}</span>                            
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4"> Status {{ pedido.status.toLowerCase() }} </v-col>
                        <v-col> Protocolo {{ pedido.id }}  </v-col>
                    </v-row>

                    <v-row v-if="$vuetify.breakpoint.smAndUp">
                        <v-col>

                            <DataTable :value="pedido.parcelas_afetadas" 
                                    :scrollable="true" scrollHeight="250px"
                                    sortField="data_compensacao_original" :sortOrder="-1"
                                    dataKey="id">

                                <Column field="data_compensacao_original" :styles="{'min-width':'190px'}"
                                        header="Compensação original" sortable>
                                    <template #body="slot">
                                        {{  slot.data.data_compensacao_original | formatDate({ dateStyle: "short" }) }}
                                    </template>
                                </Column>

                                <Column field="valor" header="Valor bruto">
                                    <template #body="slot">
                                        R$ {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 })}}
                                    </template>
                                </Column>
                                <Column field="valor_taxa_antecipacao" header="Taxa antecipação">
                                    <template #body="slot">
                                        R$ {{ slot.data.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2 })}}
                                    </template>
                                </Column>
                                
                                <Column field="valor_liquido" header="Valor líquido">
                                    <template #body="slot">
                                        R$ {{ slot.data.valor_liquido | formatMoney({ minimumFractionDigits: 2 })}}
                                    </template>
                                </Column>
                                
                            </DataTable>
                        </v-col>
                    </v-row>

                </v-card-text>

            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {

    name: "DialogPedidoAntecipacao",
    components: {
        DataTable,
        Column
    },
    props: {
        pedido: {
            type: Object,
            default: () => ({})
        },
        fechar: Function
    },
    computed:{
        showDialog(){
            return this.pedido != null;
        }
    }

}
</script>
<style scoped>

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
.center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>