<template>
  <v-container fluid>
    <div class="d-flex justify-end mb-3 mt-n9 div-alert">
      <div style="display: inline-block; width: auto; text-align: left;">
        <v-alert
          :type="STATUS_EMPRESA[empresa.status].type"
          v-if="empresa.status"
          dense
          text
        >
          <b>{{ STATUS_EMPRESA[empresa.status].texto }}</b>
        </v-alert>
      </div>
    </div>
    <v-card outlined class="mt-3 card-principal px-3 py-3">
      <v-skeleton-loader
        class="mb-2"
        :loading="loadingSkeleton"
        width="100%"
        type="heading"
      >
        <v-row class="mx-auto">
          <v-col cols="6">
            <b class="titulo-principal text-subtitle-2">Dados Gerais</b>
          </v-col>
        </v-row>
      </v-skeleton-loader>

      <v-skeleton-loader
        class="mb-2 px-5"
        :loading="loadingSkeleton"
        width="100%"
        type="text@6"
      >
        <v-row class="mt-n5 mb-5">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" sm="12">
                <div class="mt-n3">
                  <v-card-subtitle class="subtitle-text">CNPJ</v-card-subtitle>
                  <v-card-text class="mt-n5">
                    <span v-if="empresa.cnpj">{{ empresa.cnpj }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" sm="12">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Razão Social</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span v-if="empresa.razao_social">{{
                      empresa.razao_social
                    }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" sm="12">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Nome Fantasia</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span v-if="empresa.nome_fantasia">{{
                      empresa.nome_fantasia
                    }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" xl="4" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Inscrição Municipal</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span v-if="empresa.inscricao_municipal">{{
                      empresa.inscricao_municipal
                    }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="coluna-inscricao-estadual" cols="12" xl="6" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Inscrição Estadual</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span v-if="empresa.inscricao_estadual">{{
                      empresa.inscricao_estadual
                    }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" xl="4" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Natureza Jurídica</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span v-if="empresa.tipo">{{ empresa.tipo }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="coluna-cnae" cols="12" xl="6" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text">CNAE</v-card-subtitle>
                  <v-card-text class="mt-n5"> {{ empresa.cnae }} </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" xl="4" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Data Abertura</v-card-subtitle
                  >
                  <v-card-text class="mt-n5 campo-data">
                    {{ empresa.data_abertura }}
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="faturamento-media" cols="12" xl="5" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Faturamento Médio Mensal</v-card-subtitle
                  >
                  <v-card-text class="mt-n5"
                    >R$ {{ empresa.faturamento_medio }}
                  </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5 endereco-completo">
              <v-col cols="12" sm="12">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Endereço</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span>{{ empresa.enderecoCompleto }}</span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-divider inset vertical class="my-4 custom-divider"></v-divider>

          <v-col cols="12" md="6">
            <v-row class="row-endereco">
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="12"
              >
                <div class="mt-n3">
                  <v-card-subtitle class="subtitle-text"
                    >Endereço</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span>{{ empresa.endereco }}, {{ empresa.numero }}</span>
                    <span v-if="empresa.complemento"
                      >, {{ empresa.complemento }}
                    </span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5 row-bairro-cidade-cep-up">
              <v-col cols="12" xl="3" lg="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Bairro</v-card-subtitle
                  >
                  <v-card-text class="mt-n5 campo-bairro">
                    {{ empresa.bairro }}
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="coluna-cidade" cols="12" xl="9" lg="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Cidade</v-card-subtitle
                  >
                  <v-card-text class="mt-n5 campo-cidade">
                    {{ empresa.cidade }}
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="mt-n5" cols="12" xl="3" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text">CEP</v-card-subtitle>
                  <v-card-text class="mt-n5"> {{ empresa.cep }} </v-card-text>
                </div>
              </v-col>
              <v-col class="mt-n5" cols="12" xl="2" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text">UP</v-card-subtitle>
                  <v-card-text class="mt-n5"> {{ empresa.uf }} </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" xl="3" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Telefone</v-card-subtitle
                  >
                  <v-card-text class="mt-n5 campo-telefone">
                    {{ empresa.telefone }}
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="coluna-email" cols="12" xl="4" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text">Email</v-card-subtitle>
                  <v-card-text class="mt-n5"> {{ empresa.email }} </v-card-text>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-n5">
              <v-col cols="12" xl="3" md="6">
                <div class="mt-n7">
                  <v-card-subtitle class="subtitle-text"
                    >Código IBGE</v-card-subtitle
                  >
                  <v-card-text class="mt-n5 campo-ibge">
                    <span v-if="empresa.codigo_ibge">{{
                      empresa.codigo_ibge
                    }}</span>
                    <span v-else>Não informado</span>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-skeleton-loader>
    </v-card>
    <v-row class="d-flex flex-wrap">
      <v-col cols="12" md="6" class="d-flex">
        <v-card v-if="empresa.taxas.length > 0" outlined class="mt-3 px-3 py-3 flex-grow-1 card-principal">
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Taxas</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="image"
          >
            <v-row class="d-flex flex-wra mx-auto justify-center">
              <v-col cols="12">
                <v-simple-table class="border">
                  <template v-slot:default>
                    <thead>
                    <tr class="text-center border-bottom">
                      <th>Tipo transação</th>
                      <th>MDR</th>
                      <th>Taxa GW</th>
                      <th>Taxa antifraude</th>
                      <th>Compensação</th>
                      <th>Parcelas</th>
                      <th>Modalidade</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(taxa, index) in empresa.taxas" :key="index" class="text-center border-bottom">
                      <td>{{ TIPO_TRANSACAO[taxa.tipo_transacao] }}</td>
                      <td v-if="taxa.tipo === 'FIXO'">R${{taxa.taxa_transacao | formatMoney({ minimumFractionDigits: 2 }) }}</td>
                      <td v-else>{{taxa.taxa_transacao | formatMoney({ minimumFractionDigits: 2 }) }}%</td>
                      <td v-if="taxa.taxa_gateway > 0">R$ {{taxa.taxa_gateway| formatMoney({ minimumFractionDigits: 2 }) }}</td>
                      <td v-else>-</td>
                      <td v-if="empresa.mdr && empresa.mdr.antfraude_ativo && taxa.tipo_transacao == 'CREDITO' && taxa.origem == 'ON'">R$ {{taxa.taxa_antfraude| formatMoney({ minimumFractionDigits: 2 }) }}</td>
                      <td v-else>-</td>
                      <td>D + {{ taxa.qtd_dias_compensacao }}</td>
                      <td v-if="taxa.minimo_parcela === 1 && taxa.maximo_parcela === 1">À vista</td>
                      <td v-else>De {{ taxa.minimo_parcela }} a {{ taxa.maximo_parcela }}</td>
                      <td>{{ taxa.origem | formatOrigem() }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

<!--              <v-col-->
<!--                  v-for="(taxa, index) in empresa.taxas"-->
<!--                  :key="index"-->
<!--                  cols="12"-->
<!--                  xl="4"-->
<!--                  lg="6"-->
<!--                  md="6"-->
<!--                  sm="6"-->
<!--                  class="d-flex flex-column align-center justify-center"-->
<!--              >-->
<!--                <v-card width="100%" class="flex-grow-1">-->
<!--                  <v-card-title class="text-caption">{{ TIPO_TRANSACAO[taxa.tipo_transacao] }}</v-card-title>-->

<!--                  <v-row dense class="line mx-auto">-->
<!--                    <v-col><v-card-subtitle class="text-caption">MDR</v-card-subtitle></v-col>-->
<!--                    <v-col class="text-end">-->
<!--                      <v-card-subtitle class="text-caption" v-if="taxa.tipo === 'FIXO'">R${{taxa.taxa_transacao | formatMoney({ minimumFractionDigits: 2 }) }}</v-card-subtitle>-->
<!--                      <v-card-subtitle class="text-caption" v-else>{{taxa.taxa_transacao | formatMoney({ minimumFractionDigits: 2 }) }}%</v-card-subtitle>-->
<!--                    </v-col>-->
<!--                  </v-row>-->

<!--                  <v-row dense v-if="taxa.taxa_gateway > 0" class="line mx-auto mt-n8">-->
<!--                    <v-col><v-card-subtitle class="text-caption">Taxa GW</v-card-subtitle></v-col>-->
<!--                    <v-col class="text-end"><v-card-subtitle class="text-caption">R$ {{taxa.taxa_gateway| formatMoney({ minimumFractionDigits: 2 }) }}</v-card-subtitle></v-col>-->
<!--                  </v-row>-->

<!--                  <v-row dense v-if="empresa.mdr && empresa.mdr.antfraude_ativo &&taxa.tipo_transacao == 'CREDITO' &&taxa.origem == 'ON'" class="line mx-auto">-->
<!--                    <v-col><v-card-subtitle class="text-caption">Taxa antifraude</v-card-subtitle></v-col>-->
<!--                    <v-col class="text-end"><v-card-subtitle class="text-caption">R$ {{taxa.taxa_antfraude| formatMoney({ minimumFractionDigits: 2 }) }}</v-card-subtitle></v-col>-->
<!--                  </v-row>-->

<!--                  <v-row dense class="line mx-auto mt-n8">-->
<!--                    <v-col><v-card-subtitle class="text-caption">Compensação</v-card-subtitle></v-col>-->
<!--                    <v-col class="text-end"><v-card-subtitle class="text-caption">D + {{ taxa.qtd_dias_compensacao }}</v-card-subtitle></v-col>-->
<!--                  </v-row>-->

<!--                  <v-row dense class="line mx-auto mt-n8">-->
<!--                    <v-col><v-card-subtitle class="text-caption">Parcelas</v-card-subtitle></v-col>-->
<!--                    <v-col class="text-end">-->
<!--                      <v-card-subtitle class="text-caption" v-if="taxa.minimo_parcela === 1 && taxa.maximo_parcela === 1">À vista</v-card-subtitle>-->
<!--                      <v-card-subtitle class="text-caption" v-else>De {{ taxa.minimo_parcela }} a{{ taxa.maximo_parcela }}</v-card-subtitle>-->
<!--                    </v-col>-->
<!--                  </v-row>-->

<!--                  <v-row dense class="line mx-auto mb-2 mt-n8">-->
<!--                    <v-col><v-card-subtitle class="text-caption">Modalidade</v-card-subtitle></v-col>-->
<!--                    <v-col class="text-end">-->
<!--                      <v-card-subtitle class="text-caption" >{{ taxa.origem | formatOrigem() }}</v-card-subtitle>-->
<!--                    </v-col>-->
<!--                  </v-row>-->

<!--                </v-card>-->
<!--              </v-col>-->
            </v-row>
          </v-skeleton-loader>

        </v-card>
        <v-card
            outlined
            v-else
            class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
              class="mb-2"
              :loading="loadingSkeleton"
              width="100%"
              type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Taxas</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-skeleton-loader
              class="mb-2"
              :loading="loadingSkeleton"
              width="100%"
              type="list-item-avatar"
          >
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="text-empty text-caption px-2 py-2"
                  >Nenhuma taxa cadastrada.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="card-titulo-fiscal d-flex">
        <v-card outlined class="mt-3 px-3 py-3 flex-grow-1 card-principal">
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Fiscal</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-skeleton-loader
            class="mb-2 px-5"
            :loading="loadingSkeleton"
            width="100%"
            type="text@4"
          >
            <v-row class="mt-n5">
              <v-col cols="12" xl="3" lg="6" md="6" sm="6">
                <v-switch
                  color="green"
                  v-model="empresa.emite_nfse"
                  label="Emissão de NFS-e"
                  @change="handleChangeNFS"
                >
                </v-switch>
              </v-col>
              <v-col
                class="coluna-emissao-nfc"
                cols="12"
                xl="3"
                lg="6"
                md="6"
                sm="6"
              >
                <v-switch
                  color="green"
                  v-model="empresa.emite_nfce"
                  label="Emissão de NFC-e"
                  @change="handleChangeNFC"
                >
                </v-switch>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.lgAndDown ? 'mt-n10' : ''"
                cols="12"
                xl="3"
                lg="6"
                md="6"
                sm="6"
              >
                <v-switch
                  color="green"
                  v-model="empresa.optante_simples_nacional"
                  label="Simples Nacional"
                  @change="handleSave"
                >
                </v-switch>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.lgAndDown ? 'mt-n10' : ''"
                cols="12"
                xl="3"
                lg="6"
                md="6"
                sm="6"
              >
                <v-switch
                  color="green"
                  v-model="empresa.incentivador_cultural"
                  label="Incentivador Cultural"
                  @change="handleSave"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row class="mt-n3">
              <v-col v-if="showInputsNFC" cols="12" xl="4" lg="4" md="6">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Token NFC-e</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-text-field
                        required
                        dense
                        v-model="empresa.id_token_nfce"
                        maxlength="8"
                    ></v-text-field>
                  </v-card-text>
                </div>
              </v-col>
              <v-col
                v-if="showInputsNFC"
                class="col-csc-ncf"
                cols="12"
                :xl="
                  empresa?.validade_certificado && empresa.emite_nfce ? 4 : 6
                "
                :lg="
                  empresa?.validade_certificado && empresa.emite_nfce ? 4 : 6
                "
                md="6"
              >
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Código de Segurança NFC-e</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-text-field
                        required
                        dense
                        v-model="empresa.csc_nfce"
                    ></v-text-field>
                  </v-card-text>
                </div>
              </v-col>
              <v-col
                class="col-validade-certificado"
                v-if="empresa?.validade_certificado && empresa.emite_nfce"
                xl="4"
                lg="4"
                md="6"
              >
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Validade Certificado Digital</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span>{{ empresa.validade_certificado }}</span>
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="6">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Série RPS</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
<!--                    <span v-if="empresa.serie_rps">{{-->
<!--                      empresa.serie_rps-->
<!--                    }}</span>-->
<!--                    <span v-else>Não informado</span>-->
                    <v-text-field
                        dense
                        v-model="empresa.serie_rps"
                        @blur="handleSave"
                    ></v-text-field>
                  </v-card-text>
                </div>
              </v-col>
              <v-col
                class="col-regime-especial-tributacao"
                cols="12"
                :xl="
                  empresa?.validade_certificado && !empresa.emite_nfce ? 4 : 6
                "
                :lg="
                  empresa?.validade_certificado && !empresa.emite_nfce ? 4 : 6
                "
                md="6"
              >
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Regime Especial Tributação</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-select
                      :items="regime_especial_tributacao"
                      item-text="texto"
                      item-value="valor"
                      required
                      dense
                      v-model="empresa.regime_especial_tributacao"
                      @change="handleSave"
                      clearable
                    ></v-select>
                  </v-card-text>
                </div>
              </v-col>
              <v-col
                class="col-validade-certificado"
                v-if="empresa?.validade_certificado && !empresa.emite_nfce"
                cols="12"
                xl="4"
                lg="4"
                md="6"
              >
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Validade Certificado Digital</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <span>{{ empresa.validade_certificado }}</span>
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Regime Tributário</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-select
                      :items="regime_tributario"
                      required
                      dense
                      item-text="texto"
                      item-value="valor"
                      v-model="empresa.regime_tributario"
                      @change="handleSave"
                      clearable
                    ></v-select>
                    <!--                    <span v-if="empresa.regime_tributario">{{ getRegimeTri(empresa.regime_tributario) }}</span>-->
                    <!--                    <span v-else>Não informado</span>-->
                  </v-card-text>
                </div>
              </v-col>
              <v-col class="col-tributacao-rps" cols="12" md="10">
                <div class="mt-n5">
                  <v-card-subtitle class="subtitle-text"
                    >Tributação RPS</v-card-subtitle
                  >
                  <v-card-text class="mt-n5">
                    <v-select
                      :items="tributacao_rps"
                      item-text="texto"
                      item-value="valor"
                      required
                      dense
                      v-model="empresa.tributacao_rps"
                      type="number"
                      @change="handleSave"
                      clearable
                    ></v-select>
                  </v-card-text>
                </div>
              </v-col>
              <v-col v-if="showBtnCertificadoDigital" cols="12" xl="4" lg="4" md="6">
                <v-btn
                  color="blue"
                  outlined
                  @click="openDialogEnviarCertificado()"
                >
                  {{ empresa.validade_certificado ? 'Alterar certificado' : 'Enviar certificado' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-n3 flex-wrap">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card
          outlined
          v-if="empresa.socios.length > 0"
          class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Sócios</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-row class="mx-auto">
            <v-col
              v-for="(socio, index) of empresa.socios"
              :key="index"
              class="mt-3"
              cols="12"
              xl="6"
              lg="12"
              md="12"
            >
              <v-skeleton-loader
                class="mb-2"
                :loading="loadingSkeleton"
                width="100%"
                type="list-item-avatar"
              >
                <div class="d-flex justify-start div-socio">
<!--                  <i class="fa-style fas fa-user-circle icon-socio"></i>-->
                  <v-icon class="icon-socio"> mdi-account </v-icon>
                  <div class="ml-3 text-caption">
                    <div class="truncate">{{ socio.nome }}</div>
                    <div class="truncate">{{ socio.endereco }}, {{ socio.numero }} - {{ socio.bairro }}, {{ socio.cidade }} - {{ socio.uf }}, {{ socio.cep }}</div>
                    <div>{{ socio.email }}</div>
                    <div>{{ $masks.telefone(socio.telefone_celular) }}</div>
                    <div v-if="socio.administrador">Administrador</div>
                  </div>
                </div>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          v-else
          class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Sócios</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="list-item-avatar"
          >
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="text-empty text-caption px-2 py-2"
                    >Nenhum sócio cadastrado.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card
          outlined
          v-if="empresa.conta_correntes.length > 0"
          class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Contas</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-row class="mx-auto">
            <v-col
              v-for="(conta, index) of empresa.conta_correntes"
              :key="index"
              class="mt-3"
              cols="12"
              lg="6"
              md="6"
            >
              <v-skeleton-loader
                class="mb-2"
                :loading="loadingSkeleton"
                width="100%"
                type="list-item-avatar"
              >
                <div class="d-flex justify-start">
                  <div class="logo-banco">
                    <img
                      width="50px"
                      height="50px"
                      :title="conta.banco.codigo_febraban"
                      :src="logoBanco(conta)"
                    />
                  </div>
                  <div class="ml-3 text-caption">
                    <div>
                      {{ conta.banco.codigo_febraban }} - {{ conta.banco.nome }}
                    </div>
                    <div>
                      Agência {{ conta.agencia }} Conta
                      {{ conta.numero_conta }} {{ conta.digito_numero_conta
                      }}{{ conta.principal ? " - (Principal)" : "" }}
                    </div>
                  </div>
                </div>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          v-else
          class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Contas</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="list-item-avatar"
          >
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="text-empty text-caption px-2 py-2"
                    >Nenhuma conta cadastrada.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-n3 flex-wrap">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card
          outlined
          v-if="empresa.splits.length > 0"
          class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Splits</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-row class="mx-auto">
            <v-col
              v-for="(split, index) of empresa.splits"
              :key="index"
              class="mt-3"
              cols="12"
              lg="6"
              md="6"
            >
              <v-skeleton-loader
                class="mb-2"
                :loading="loadingSkeleton"
                width="100%"
                type="list-item-avatar-two-line"
              >
                <div class="d-flex justify-start">
                  <v-progress-circular
                    :rotate="360"
                    :size="90"
                    :width="5"
                    :value="split.valor"
                    color="#3c89fc"
                  >
                    <span
                      class="text-caption valor-split"
                      v-if="split.valor % 1 !== 0"
                      >{{ split.valor }} %</span
                    >
                    <span class="text-caption valor-split" v-else
                      >{{ split.valor }}.00 %</span
                    >
                  </v-progress-circular>
                  <div class="ml-3 mt-3 text-caption">
                    <div>{{ $masks.cnpj(split.recebedor.cnpj) }}</div>
                    <div>{{ split.recebedor.nome_fantasia }}</div>
                    <div>{{ split.recebedor.razao_social }}</div>
                    <div v-if="split.paga_taxa_transacao">Responsável Taxa</div>
                    <div v-if="split.paga_resto_divisao_taxa">Responsável Divisão</div>
                  </div>
                </div>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          v-else
          class="mt-3 px-3 py-3 flex-grow-1 card-principal"
        >
          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Splits</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-skeleton-loader
            class="mb-2"
            :loading="loadingSkeleton"
            width="100%"
            type="list-item-avatar"
          >
            <v-row>
              <v-col cols="12">
                <div>
                  <span class="text-empty text-caption px-2 py-2"
                    >Nenhum split cadastrado.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex">
        <v-card outlined class="mt-3 px-3 py-3 flex-grow-1 card-principal">
          <v-skeleton-loader
              class="mb-2"
              :loading="loadingSkeleton"
              width="100%"
              type="heading"
          >
            <v-row class="mx-auto">
              <v-col cols="6">
                <b class="titulo-principal text-subtitle-2">Documentos</b>
              </v-col>
            </v-row>
          </v-skeleton-loader>

          <v-row class="mx-auto justify-center">
            <v-col
                v-for="(documento, index) in doctosTipoEmpresa"
                :key="index"
                cols="12"
                sm="4"
                class="d-flex flex-column align-center justify-center"
            >
              <v-skeleton-loader
                  class="mb-2"
                  :loading="loadingSkeleton"
                  width="100%"
                  type="list-item-avatar"
              >
                <v-card-text class="mr-10">
                  <div class="icon-text-container">
                    <a
                        v-if="getEmpresaDocto(documento)"
                        :href="getEmpresaDocto(documento).url"
                        target="_blank"
                        class="py-0"
                    >
                      <img
                          :src="require('@/assets/img/icones/Doc_lupa_icon.svg')"
                          alt="icon"
                      />
                    </a>
                    <img
                        v-else
                        :src="
                        require('@/assets/img/icones/Doc_lupa_exclamação.svg')
                      "
                        alt="icon"
                    />
                    <p
                        class="text-caption"
                        :class="getEmpresaDocto(documento) ? 'mt-n2' : ''"
                        style="white-space: nowrap;"
                    >
                      {{ TIPO_DOCUMENTO[documento].texto }}
                    </p>
                  </div>
                </v-card-text>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" class="text-right">
        <v-btn dark color="primary" :to="{ name: 'Empresas' }">
          Voltar
        </v-btn>
      </v-col>
    </v-row>

    <div>
      <DialogEnviarCertificado
        :showDialog="enviarCerficiadoDialog.showDialog"
        @cancela-dialog="enviarCerficiadoDialog.showDialog = false"
        :empresa="empresa"
        @close-dialog="enviarCerficiadoDialog.showDialog = false"
      />
    </div>
  </v-container>
</template>

<script>
import DialogEnviarCertificado from "@/components/Dialogs/DialogEnviarCertificado.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "DetalhesEmpresa",
  components: {
    DialogEnviarCertificado
  },
  props: {
    mode: String,
    idEmpresa: {
      type: String
    }
  },
  data() {
    return {
      loadingSkeleton: true,
      empresa: {
        id: null,
        cnpj: "",
        conta_correntes: [],
        taxas: [],
        telefone: "",
        tipo: "MEI",
        data_abertura: null,
        faturamento_medio: null,
        socios: [],
        documentos: [],
        splits: [],
        status: null,
        cnae: null,
        cep: null
      },
      cnaes: [],
      doctosTipoEmpresa: [],
      regime_tributario: [
        { texto: "Simples Nacional", valor: "SIMPLES_NACIONAL" },
        {
          texto: "Simples Nacional Excesso",
          valor: "SIMPLES_NACIONAL_EXCESSO"
        },
        { texto: "Regime normal", valor: "REGIME_NORMAL" }
      ],
      regime_especial_tributacao: [
        { texto: "Microempresa municipal", valor: "MICROEMPRESA_MUNICIPAL" },
        { texto: "Estimativa", valor: "ESTIMATIVA" },
        {
          texto: "Sociedade de profissionais",
          valor: "SOCIEDADE_PROFISSIONAIS"
        },
        { texto: "Cooperativa", valor: "COOPERATIVA" },
        { texto: "MEI - Simples Nacional", valor: "MEI" },
        { texto: "ME EPP - Simples Nacional", valor: "ME" },
        {
          texto: "EIRELI - Empresário Individual de Resp. Limitada",
          valor: "EIRELI"
        }
      ],
      tributacao_rps: [
        { texto: "T - Tributado em São Paulo", valor: "T" },
        { texto: "F - Tributado Fora de São Paulo", valor: "F" },
        { texto: "A - Tributado em São Paulo (Isento)", valor: "A" },
        { texto: "B - Tributado Fora de São Paulo (Isento)", valor: "B" },
        { texto: "M - Tributado em São Paulo (Imune)", valor: "M" },
        { texto: "N - Tributado Fora de São Paulo (Imune)", valor: "N" },
        {
          texto: "X - Tributado em São Paulo (Exigibilidade Suspensa)",
          valor: "X"
        },
        {
          texto: "V - Tributado Fora de São Paulo (Exigibilidade Suspensa)",
          valor: "V"
        },
        { texto: "P - Exportação de Serviços", valor: "P" },
        { texto: "C – Isenta de ISS", valor: "C" },
        { texto: "E – Não Incidência no Município", valor: "E" },
        { texto: "F – Imune", valor: "F" },
        { texto: "K – Exigibilidd Susp.Dec.J/Proc.A", valor: "K" },
        { texto: "N – Não tributável", valor: "N" },
        { texto: "T – Tributável", valor: "T" },
        { texto: "G – Tributável Fixo", valor: "G" },
        { texto: "H – Tributável S.N.", valor: "H" },
        { texto: "M – Micro Empreendedor Individual (MEI)", valor: "M" }
      ],
      enviarCerficiadoDialog: {
        showDialog: false
      },
      showBtnCertificadoDigital: false,
      showInputsNFC: false
    };
  },
  computed: {
    ...mapState(["user", "appLoading", "config"]),
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getCNAES() {
      this.$axios
        .get("/public/cnae")
        .then(response => {
          this.cnaes = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao consultar CNAE",
            text: error.response.data
          });
        });
    },

    getEmpresa() {
      this.$axios
        .get(`/empresa/${this.idEmpresa}`)
        .then(response => {

          this.empresa = Object.assign(this.empresa, response.data);
          this.empresa.cnpj = this.$masks.cnpj(this.empresa.cnpj);
          const dataFormatada = this.$options.filters.formatDate(
            this.empresa.data_abertura
          );
          if (dataFormatada) this.empresa.data_abertura = dataFormatada;

          if (this.empresa.faturamento_medio) {
            this.empresa.faturamento_medio = this.$masks.dinheiro(
              this.empresa.faturamento_medio.toFixed(2)
            );
          }

          this.empresa.cep = this.$masks.cep(this.empresa.cep);
          this.empresa.telefone = this.$masks.telefone(this.empresa.telefone);
          const tipo = this.TIPO_EMPRESA.find(
            item => item.valor === this.empresa.natureza_juridica
          );
          this.empresa.tipo = `${this.empresa.natureza_juridica} - ${tipo?.texto}`;
          const cnae = this.cnaes.find(
            item => item.codigo === this.empresa.cnae
          );
          this.empresa.cnae = `${this.empresa.cnae} - ${cnae?.descricao}`;
          this.empresa.razao_social = this.empresa.razao_social.toUpperCase();
          this.empresa.nome_fantasia = this.empresa.nome_fantasia.toUpperCase();

          const validadeCertificadoFormatada = this.$options.filters.formatDate(
            this.empresa?.validade_certificado
          );

          if (validadeCertificadoFormatada) this.empresa.validade_certificado = validadeCertificadoFormatada;

          if(this.empresa.emite_nfse || this.empresa.emite_nfce) this.showBtnCertificadoDigital = true;

          this.showInputsNFC = this.empresa.emite_nfce;

          this.setDoctosTipoEmpresa();

          this.empresa.taxas = this.empresa.taxas.sort(this.orderingFees);

          this.loadingSkeleton = false;

        })
        .catch(error => {
          console.log(error);
          this.openDialogMessage({
            type: "error",
            title: "Erro ao consultar Empresa",
            text: error.response.data
          });

          this.$router.push({
            name: "Empresas"
          });
        });
    },

    orderingFees(a, b) {
      // Mapeamento dos tipos de transação para números para ordenação
      const tipoPrioridade = {
        CREDITO: 1,
        DEBITO: 2,
        PIX: 3,
        BOLETO: 4,
        TICKET: 5,
        VOUCHER: 6
      };

      // Prioridade para 'tipo_transacao'
      if (tipoPrioridade[a.tipo_transacao] < tipoPrioridade[b.tipo_transacao]) return -1;
      if (tipoPrioridade[a.tipo_transacao] > tipoPrioridade[b.tipo_transacao]) return 1;

      // Prioridade para 'origem'
      if (a.origem === 'ON' && b.origem === 'OFF') return -1;
      if (a.origem === 'OFF' && b.origem === 'ON') return 1;

      // Prioridade para 'minimo_parcela'
      return a.minimo_parcela - b.minimo_parcela;
    },

    setDoctosTipoEmpresa() {
      if (this.empresa.natureza_juridica === "MEI") {
        this.doctosTipoEmpresa = ["CCMEI", "COMPROVANTE_ENDERECO"];
      } else if (this.empresa.natureza_juridica === "SA") {
        this.doctosTipoEmpresa = [
          "PAGINA_ESTATUTO_SOCIAL",
          "DECLARACAO_FATURAMENTO",
          "COMPROVANTE_ENDERECO"
        ];
      } else {
        this.doctosTipoEmpresa = [
          "PAGINA_CONTRATO_SOCIAL",
          "DECLARACAO_FATURAMENTO",
          "COMPROVANTE_ENDERECO"
        ];
      }
    },

    getEmpresaDocto(tpDocto) {
      const docto = this.empresa.documentos.find(el => el.tipo === tpDocto);
      if (docto) return docto;
    },

    logoBanco(conta) {
      return `${process.env.VUE_APP_ROOT_API}/public/banco/${conta.banco.codigo_febraban}/logo`;
    },

    openDialogEnviarCertificado() {
      this.enviarCerficiadoDialog = {
        showDialog: true
      };
    },

    async handleSave() {
      const empresaObj = { ...this.empresa };
      empresaObj.cnpj = this.$masks.unMask(empresaObj.cnpj);
      empresaObj.data_abertura = empresaObj.data_abertura
        .split("/")
        .reverse()
        .join("-");
      empresaObj.faturamento_medio = this.parseToFloat(
        empresaObj.faturamento_medio
      );

      empresaObj.marketplace = { id: this.config.id_marketplace };
      empresaObj.usuarios = [
        {
          id: this.user.id
        }
      ];

      const parts = empresaObj.cnae.split(" - ");
      empresaObj.cnae = parts[0];

      if(empresaObj.validade_certificado) {
        empresaObj.validade_certificado = empresaObj.validade_certificado
            .split("/")
            .reverse()
            .join("-");
      }

      await this.$axios
        .put("/empresa", empresaObj)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Empresa Atualizada com sucesso!`,
            life: 2000
          });
          this.getEmpresa();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Atualizar",
            text: error.response.data
          });
        });
    },

    handleChangeNFS() {
      if(this.empresa.emite_nfce) {
        this.showBtnCertificadoDigital = true;
      } else {
        this.showBtnCertificadoDigital = this.empresa.emite_nfse;
      }

      if(this.empresa.validade_certificado) {
        this.handleSave();
      }

    },

    handleChangeNFC() {
      this.showInputsNFC = this.empresa.emite_nfce;

      if(this.empresa.emite_nfse) {
        this.showBtnCertificadoDigital = true;
      } else {
        this.showBtnCertificadoDigital = this.empresa.emite_nfce;
      }

      if(this.empresa.validade_certificado) {
        this.handleSave();
      }

    },

  },
  async mounted() {
    this.getCNAES();
    this.getEmpresa();
  }
};
</script>
<style scoped>
.div-alert {
  height: 50px;
}

.endereco-completo {
  display: none;
}
.card-principal {
  border: 0.5px solid #ebebeb !important;
}
.custom-divider {
  background-color: #ebebeb !important;
}

.subtitle-text {
  color: #939393;
  font-size: 0.7rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
}

.icon-text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon-text-container img {
  margin-left: 20px;
  width: 70px;
}

.icon-socio {
  font-size: 50px;
}

.titulo-principal {
  color: #3c89fc;
  font-weight: 400 !important;
  padding: 0.5rem 0 !important;
}

.campo-bairro,
.campo-telefone,
.campo-ibge {
  width: 250px;
}

.campo-cidade,
.campo-data {
  width: 280px;
}

.text-empty {
  font-size: 22px;
}

.valor-split {
  font-weight: bold !important;
}

.div-socio {
  border: 1px solid #ebebeb;
  height: 120px;
  padding: 10px 10px 10px 10px;
}

@media (max-width: 1263px) {
  .coluna-cidade {
    margin-top: -15px;
  }
}

@media (max-width: 959px) {
  .card-titulo-fiscal {
    margin-top: -10px !important;
  }

  .coluna-email,
  .coluna-inscricao-estadual,
  .col-tributacao-rps,
  .col-regime-especial-tributacao,
  .col-csc-ncf,
  .coluna-cnae,
  .col-validade-certificado,
  .faturamento-media {
    margin-top: -15px;
  }

  .endereco-completo {
    display: block;
  }

  .row-endereco,
  .row-bairro-cidade-cep-up {
    display: none;
  }

  .custom-divider {
    display: none;
  }
}

@media (max-width: 599px) {
  .coluna-emissao-nfc {
    margin-top: -40px;
  }
}

@media (max-width: 575px) {
  .div-alert {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100px;
  }

  .div-alert div {
    margin-top: 15px;
  }
}

@media (max-width: 512px) {
  .truncate {
    max-width: 200px;
  }
}

@media (max-width: 375px) {
  .text-empty {
    font-size: 18px;
  }

  .truncate {
    max-width: 150px;
  }
}
</style>
